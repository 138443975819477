import localforage from "localforage";
import { initDynamicShtorka } from "./globalModules/dynamicShtorka";
import { initSoundEngine } from "./globalModules/soundEngine";
import { initVibrateEngine } from "./globalModules/vibrateEngine";

/**
 * @param {number} min 
 * @param {number} max 
 * @returns {number}
 */
export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/** Оптимизированный querySelector. быстрее обычного в 3-250 раз
 * @param {String} selector css селектор. если нужен именно querySelector - вначале писать `@`
 * @param {Document} el родитель, в котором искать элемент
 * @param {Boolean} m опциональный. если не указан - возвращается первый найденый элемент. иначе - все
 * @returns {HTMLElement}
 */
export function qs(selector, el, m) {
	el ||= document
	switch (selector[0]) {
		case '@':
			return typeof m === 'number' ? el.querySelectorAll(selector.slice(1)) : el.querySelector(selector.slice(1))
		case '.':
			return m == 2 ? [...el.getElementsByClassName(selector.slice(1))] : (m==1 ? el.getElementsByClassName(selector.slice(1)) : el.getElementsByClassName(selector.slice(1))[0])
		case '#':
			return el.getElementById(selector.slice(1))
		default:
			return m == 2 ? [...el.getElementsByTagName(selector)] : (m==1 ? el.getElementsByTagName(selector) : el.getElementsByTagName(selector)[0])
	}
}

window.qs=qs
if (!localStorage.store) localStorage.store='{}'
function ls(key) {
	if (key) {
		if (key == 'indb') {
			return new Promise((resolve) => {
				localforage.getItem('store').then((v) => {
					resolve(JSON.parse(v))
				})
			});
		} else {
			let st = JSON.parse(localStorage.store)
			return eval(`st.${key.replaceAll('.', '?.')}`)
			// return key.split('.').reduce((o, k) => (o && o[k] ? o[k] : null), JSON.parse(localStorage.store));
		}
	}
	else {
		return JSON.parse(localStorage.store);
	}
}
window.ls = ls;
let nowVibrating = 0

let skillsSkins = {
	bounse(el) {
		el.style.transform = 'scale(1.1)'
		setTimeout(() => {
			el.style.transform = ''
		}, 200)
	},
	support_format_webp() {
		var elem = document.createElement('canvas')
		return !(!elem.getContext || !elem.getContext('2d')) && 0 == elem.toDataURL('image/webp').indexOf('data:image/webp')
	},
	getRandomInRange(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min
	},
	getCookie(name) {
		let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'))
		return matches ? decodeURIComponent(matches[1]) : void 0
	},
	setCookie(name, value, options = {}) {
		(options = {
			path: '/',
			...options
		}).expires instanceof Date && (options.expires = options.expires.toUTCString())
		let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)
		for (let optionKey in options) {
			updatedCookie += '; ' + optionKey
			let optionValue = options[optionKey]
			!0 !== optionValue && (updatedCookie += '=' + optionValue)
		}
		document.cookie = updatedCookie
	},
	vibrate(ms, coeff) {
		let st = ms;
		/**
		 * Вибрация
		 * @param {number | array} ms длительность вибрации 
		*/
		if (typeof (ms) == 'string') {
			switch (ms) {
				case 'step':
					ms = [30]
					break
				case 'tap':
					ms = [20]
					break
				case 'lil success':
					ms = [15, 8, 30]
					break
				case 'big success':
					ms = [20, 10, 25, 10, 30]
					break
				case 'success':
					ms = [15, 7, 20, 7, 20]
					break
				case 'big fail':
					ms = [20, 8, 20, 8, 20, 50, 20]
					break
				case 'fail':
					ms = [30, 8, 20, 100, 30]
					break
				case 'warn':
					ms = [20, 20, 20, 20, 20, 90, 20]
					break

				default:
					break
			}

		}
		const canVibrate = window.navigator.vibrate
		if (canVibrate && state.settings.vibro.status && (coeff==undefined || coeff)) {
			if (+new Date - nowVibrating > 30) {
				// console.log(ms.map(el => el * (coeff ?? 1)))
				window.navigator.vibrate(ms.map(el => el * (coeff??1) ))
				if(st!='step') nowVibrating = +new Date
			}
			// alert(ms)
		}
			
	},
	bg(prop) {
		let bg = q('.qbg')
		console.log(prop)
		if (prop.close) {
			bg.attr('close', prop.close)
		}
		if (prop.act == 'close') {
			bg.removeClass('backdrop-in')
			q(bg.attr('close')).toggleClass('act modal-in')
			setTimeout(() => {
				bg.hide()

				bg.attr('close', '')
			}, 300)
		}
		else if (prop.act || !bg[0].classList.contains('backdrop-in')) {
			bg.show()
			setTimeout(() => {
				bg.addClass('backdrop-in')
			}, 5)

			if (prop.callback !== undefined) {
				bg.once('click', () => (prop.callback()))
			}
			if (prop.closeOnClick) {
				bg.once('click', () => {
					bg.removeClass('backdrop-in')
					setTimeout(() => {
						bg.hide()
						bg.off('click', () => (callback()))
						bg.attr('close', '')
					}, 300)
				})
			}
		} else if (prop.act == false || bg[0].classList.contains('backdrop-in')) {

			bg.removeClass('backdrop-in')
			setTimeout(() => {
				bg.hide()
				bg.off('click')
			}, 300)
		}

	},
	say(text) {
		app.toast.create({text, closeTimeout: 2500}).open()
	},
	teach(props) {
		props.time ??= 4000
		props.ico ??= '<div id="toastBulb"></div>'
		props.btn ??= 'Ок'
		props.condition ??= 1

		let toastTeach = app.toast.create({
			text: props.text,
			closeButton: props.btn,
			closeButtonText: props.btn,
			closeTimeout: props.time,
			destroyOnClose: 1,
			icon: props.ico,
		})
		// let bulbAnimation
		toastTeach.on('open', (e) => {
			// bulbAnimation = this.bulb(e)
		})
		toastTeach.on('opened', (e) => {
			// bulbAnimation.play()
		})
		toastTeach.on('close', () => {

			props.callback !== undefined ? props.callback() : null
			app.emit('e-backp')
		})
		console.log(props, toastTeach)
		if (props.condition) {
			console.log(1)
			toastTeach.open()
		}

	},
	error(props) {
		props.btn ??= 'Ок'
		app.toast.create({
			text: props.text,
			cssClass: 'error',
			closeButton: props.buttonBool??true,
			destroyOnClose: 1,
			closeTimeout: props.time || 2000,
			closeButtonText: props.btn,
			on: {
				closeButtonClick: () => {
					if (props.callback !== undefined) props.callback()
				}
			}
		}).open()
		app.emit('e-error')
	},
	getWord(words, word) {
		let n = word + ''
		// let words = ['дней', 'день', 'дня']

		if (last(n, 1) == '0' || last(n, 1) == '5' || last(n, 1) == '6' ||
			last(n, 1) == '7' || last(n, 1) == '8' || last(n, 1) == '9' || last(n, 2) == '10' ||
			last(n, 2) == '11' || last(n, 2) == '12' || last(n, 2) == '13' || last(n, 2) == '14') {
			return n + ' ' + words[0]
		} else if (last(n, 1) == '1') {
			return n + ' ' + words[1]
		} else if (last(n, 1) == '2' || last(n, 1) == '3' || last(n, 1) == '4') {
			return n + ' ' + words[2]
		}

		function last(str, c) {
			return str.slice(c * -1)
		}
	},
	bigToast(props) {
		let rand = `toastMood${window.gf.getRandomInRange(0, 1000000000000)}`
		let toast = app.toast.create({
			icon: `<div id="${rand}" class="thumbAni"></div>`,
			text: props.text,
			position: 'center',
			horizontalPosition: 'center',
			closeTimeout: 3000,

		})
		let cVariant;
		if ((typeof props.anim == 'string')) {
			cVariant = props.anim
			run(cVariant)
		} else {

			getRandomVariant(props.anim)

		}


		function run(vari) {
			toast.open()

			let animation = lottie.loadAnimation({
				container: document.querySelector(`#${rand}`), // Required
				path: `anim/${vari}.json`, // Required
				renderer: 'canvas',
				loop: false,
				autoplay: false, // Optional
				rendererSettings: {
					progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
				}
			})

			animation.goToAndPlay(0, true)
		}

		function getRandomVariant(variants) {
			let r = window.gf.getRandomInRange(0, variants.length - 1)
			let vari = variants[r];

			if (state.appData?.lastShownToastEmoji == vari) {
				getRandomVariant(variants)
			} else {
				state.appData.lastShownToastEmoji = vari
				// console.log(vari)
				console.log(r, vari)
				run(vari)
			}
		}
	},
	ls(key) {

		try {
			return JSON.parse(localStorage[key])
		} catch (error) {
			return localStorage[key]
		}

	},
	jp(wh) {
		try {
			return JSON.parse(wh)
		} catch (error) {
			// debugger
			// console.error(error)
			return wh
		}
	},
	jstr(wh) {
		try {
			return JSON.stringify(wh)
		} catch (error) {
			// debugger
			// console.error(error)
			return wh
		}
	},
}

window.gf = skillsSkins

export default () => {
	if (!localStorage.store) localStorage.store='{}' 
	console.log('globalF')

	let curView = '';

	q(document).on('contextmenu', 'a, img, video', (e) => {
		if (window.state.settings.native) {
			console.log('prevern')
			e.preventDefault()
			e.stopPropagation();
			return false;
		}
	})

	// q(document).on('click', 'a, img, video', (e) => {
	// 	if (window.state.settings.native) {
	// 		console.log('prevent')
	// 		e.preventDefault();
	// 		e.stopPropagation();
	// 		return false;
	// 	}
	
	// });



	document.addEventListener('touchmove', e => {
		if (e?.touches?.length > 1 && window.state.settings.native) {
			e.preventDefault()
		}
	}, { passive: false })

	document.addEventListener('mousewheel', function (e) {
		if (e.ctrlKey && window.state.settings.native) {
			e.preventDefault()

			// perform desired zoom action here
		}
		if (app.device.desktop) {
			let ttip = qs('.tooltip-in')
			if (ttip) {
				ttip.classList.remove('tooltip-in')
			}
		}

	}, { passive: false })
	let isDesktop = app.device.desktop
	document.addEventListener('mousemove', function (e) {
		if (isDesktop) {
			let ttip = qs('.tooltip-in')
			if (ttip && !e.target.closest('.ttip')) {
				ttip.classList.remove('tooltip-in')
			}
		}

	}, { passive: false })

	//возвращение назад при повтором нажатии на таб
	q(document).on('click', '.tab-link:not(.noBack)', (e) => {
		if (app.view.current.index == curView) {
			app.views[app.view.current.index].router.back()
			console.log('tabBack')


		}
		curView = app.view.current.index
	})

	//инит тултипов
	setInterval(() => {
		let ttipEls = document.querySelectorAll('.ttip:not([init="1"])')
		for (let index = 0; index < ttipEls.length; index++) {
			const element = ttipEls[index]
			if (element.getAttribute('data-only') == null || element.getAttribute('data-only') == curDevice) {

				let ttext = element.getAttribute('ttipText')

				try { app.tooltip.get(element).destroy() } catch (error) { }
				app.tooltip.create({
					targetEl: element,
					text: ttext,
					cssClass: (element.getAttribute('delay') !== null ? 'd' + element.getAttribute('delay') : '') + (element.getAttribute('tail') !== null ? ' tail ' + element.getAttribute('tail') : ''),
					on: {
						show: function (e) {
							setTimeout(() => {
								if (document.querySelector('.tooltip-in') !== null) {
									// window.gf.vibrate('lil success')
								}
							}, 400)
							if (element.getAttribute('ttipCondition') !== null && !eval(element.getAttribute('ttipCondition'))) {
								e.hide()
								setTimeout(() => {
									e.hide()
								}, 20)
								setTimeout(() => {
									e.hide()
								}, 70)
								setTimeout(() => {
									e.hide()
								}, 170)
								setTimeout(() => {
									e.hide()
								}, 470)
								setTimeout(() => {
									e.hide()
								}, 970)

							}
						}
					}
				})
			}
			element.setAttribute('init', 1)
		}

		q('.modal-out.error').remove()
	}, 800)

	setInterval(() => {
		let toggles = qs('.toggle-init', 0, 1)
		for (let index = 0; index < toggles.length; index++) {
			const element = toggles[index];
			element
		}
	}, 900);

	let state = {appData:{}}

	//отменяет перетаскивание мышкой ссылок и картинок
	q(document).on('dragstart', 'img, a', function (event) { event.preventDefault() })


	q(window).on('popstate', function () {
		// $('#myModal').modal('hide');
		closeModal(true)
	})

	function closeModal(nohistory) {
		if (state.appData.modalOpened) {
			console.log('hide')
			state.appData.modalOpened = false
			if (!nohistory) {
				history.back()
			}
			try { app.actions.get('.actions-modal.modal-in').close() } catch (error) { }
			try { app.actions.get('.dialog.modal-in').close() } catch (error) { }
			try { app.actions.get('.popover.modal-in').close() } catch (error) { }
			try { app.dialog.get('.dialog.modal-in').close() } catch (error) { }
			try { app.dialog.get('.sheet-modal.modal-in').close() } catch (error) { }
			try { app.popover.get('.popover.modal-in').close() } catch (error) { }
			try { store.state.gf.bg({ act: 'close' }) } catch (error) { }

		}
	}

	function closeNoModals() {
		try { app.actions.get('.toast.modal-in:not(.toast-center)').close() } catch (error) { }
	}

	setInterval(() => {
		function br() {
			function hasModal() {
				let items = qs('.modal-in', null, 1)
				for (let i = 0; i < items.length; i++) {
					if (!(items[i].classList.contains('toast') || items[i].classList.contains('popup'))) {
						return true
					}
				}
			}

			function hasSpecModal(type) {
				let els = qs('.modal-in', null, 1)
				for (let i = 0; i < els.length; i++) {
					if (els[i].classList.contains(type)) return true
				}
			}
			if (hasModal()) {

				if ((hasSpecModal('actions-modal') || hasSpecModal('dialog') || hasSpecModal('sheet-modal') || hasSpecModal('popover') || hasSpecModal('act'))
					&& !state.appData.modalOpened) {
					state.appData.modalOpened = true
					window.history.pushState('forward', null, './#modal')
				}
			} else {
				if (state.appData.modalOpened) {
					state.appData.modalOpened = false
					history.back()
				}

			}
		}
		br()
	}, 100)

	initDynamicShtorka()
	initSoundEngine()
	initVibrateEngine()

	


	//установка нормальных свойств инпута в диалог.промпт
	app.on('dialogOpen', (ev) => {
		let el = qs('input', ev.el)
		if (!el) return 0
		el.setAttribute('type', 'search')
		el.setAttribute('spellcheck', 'false')
		let length = el.value.length
		el.focus();
		el.setSelectionRange(length, length);
	})
}

/**
 * @param {Date} date 
 * @returns {String}
 */
export function rusDate(date) {
	const formatter = new Intl.DateTimeFormat('ru-RU', {
		day: 'numeric',
		month: 'long',
		year: 'numeric'
	});

	return formatter.format(date);
}