<template>
	<div class="joinGroup">
		<f7-block>
			<h2>Добро пожаловать!</h2>
			<p>Следующий шаг – вступить в свою группу</p>
		</f7-block>
		<f7-list>
			<div class="grid strong grid-cols-2 grid-gap padding-horizontal margin-horizontal">
				<f7-button @click="joinGroup" tonal>Присоедениться</f7-button>
				<f7-button href="/createGroup/" tonal>Содать группу</f7-button>
			</div>
			<f7-list-item>
			</f7-list-item>
		</f7-list>
	</div>
</template>

<script setup>
let pr = defineProps(['f7router'])
function joinGroup() {
	console.log(pr.f7router)
	window.dataTransfer = {
		length: 10,
		dataName: 'приглашения',
	}
	
	app.views.current.router.navigate('/dataTransfer/get/lupa/')
}
</script>

<style lang="less" scoped></style>