import App from './../components/app.vue'
const transition = () => 'md-flow';
let defOptions = {
	browserHistory: 1,
	transition: 'md-flow',
}
let defPopup = {
	closeOnEscape: true,
	on: {
		open: (e) => {
		},
		opened: (e) => {


		},
		close: (e) => {

		},
		closed: (e) => {
			// console.log(e)
		},
	}
}

var routes = [
	{
		path: '/achive/:type/',
		options: {
			browserHistory: true,
			keepAlive: false,
		},
		popup: {
			asyncComponent: () => import('./../components/achive/achive.vue'),
			...defPopup
		}
	},
	{
		path: '/proTip/',
		options: {
			browserHistory: true,
			keepAlive: false,
		},
		popup: {
			asyncComponent: () => import('./../components/pro/proTip.vue'),
			...defPopup
		}
	},
	{
		path: '/settingsAccount/:name/',
		asyncComponent: () => import('./../pages/settings/settingsAccount.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/dataTransfer/:name/:type/',
		asyncComponent: () => import('./../pages/dataTransfer.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/dataTransferlink/',
		asyncComponent: () => import('./../components/dataTransfer/link.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/newTask/',
		asyncComponent: () => import('./../pages/home/newTask.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/post/:id/:title/',
		asyncComponent: () => import('./../pages/home/post.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/reactioners/',
		asyncComponent: () => import('./../pages/home/reactioners.vue'),
		options: {
			...defOptions,

		},
	},
	// {
	// 	path: '/',
	// 	component: App,
	// 	options: {
	// 		browserHistory: 1,
	// 		transition: transition(),
	// 		keepAlive: true,
			
			
	// 	},
	// },
	{
		path: '/register/',
		asyncComponent: () => import('./../pages/register.vue'),
		options: {
			browserHistory: 1,
			transition: transition(),
			
		},
	},
	{
		path: '/createGroup/',
		asyncComponent: () => import('./../pages/groups/createGroup.vue'),
		options: {
			browserHistory: 1,
			transition: false,
			
		},
	},
	{
		path: '/joinGroup/:id/',
		asyncComponent: () => import('./../pages/groups/joinGroup.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/auth/',
		asyncComponent: () => import('./../pages/auth.vue'),
		options: {
			browserHistory: 0,
			transition: false,
			
		},
	},
	// {
	// 	path: '/settingsAppSound/',
	// 	asyncComponent: () => import('./../pages/settingsAppSound.vue'),
	// 	options: {
	// 		browserHistory: 1,
	// 		transition: transition(),
	// 		keepAl,
			
	// 	},
	// }
	{
		path: '/selectUsers/',
		popup: {
			asyncComponent: () => import('./../components/groups/selectUsers.vue'),
			...defPopup,
			swipeToClose: 'to-bottom',
			swipeHandler: '.handler.selectUsers',
		},
		options: {
			browserHistory: 1,
		},
	},
	{
		path: '/exportPost/:id/:title/',
		popup: {
			asyncComponent: () => import('./../components/home/feed/exportPost.vue'),
			...defPopup,
			swipeToClose: 'to-bottom',
			swipeHandler: '.handler.exportPost',
		},
		options: {
			browserHistory: 1,
		},
	},
];

export default routes;
