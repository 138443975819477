<template>
	<a class="post block block-outline inset ripple ripple-blur oh" :href="`/post/${post.id}/${post.title||' '}/`" >
		
		<!-- <div class="title">{{ post.title }}</div> -->
		
	<div class="block-header">
		<div class="user"><UserInList :user="post.user"/></div>
		<div class="date" v-if="typeof post.created_at == 'number'">{{postDate}}</div>
	</div>
	<h2 class="title">{{ post.title }}</h2>
    <div class="content" v-html="content"></div>
	<div class="block-footer" v-if="post.isLimited"><a :href="`/post/${post.id}/${post.title}/`" class="no-ripple longread">Читать далее...</a></div>
	<div class="bott"><div v-if="post.comment_count" class="comments">
		<span class="msr inline">comment</span> {{ post.comment_count }}
		</div>
		<div class="reactions" v-if="Object.keys(post.reactions).length">
			<div class="react" v-for="count, emojie in post.reactions">{{ emojie }} {{ count }}</div>
		</div></div>
  </a>
</template>

<script>
import { Transition, computed, ref, watchEffect } from 'vue';
import { usePostsStore } from '../../../js/stores/postsStore'
import './post.less'
import { io } from 'socket.io-client'
import DText from '../../ui/dText.vue';
import { f7 } from 'framework7-vue';
import UserInList from '../../groups/userInList.vue';
export default {
  props: ['id', 'title'],
	setup(p) {
		const posts = usePostsStore()
		const post = computed(()=> posts.postById(p.id, p.title) )
		const content = ref(null);
		watchEffect(async () => {
			content.value = await posts.content(post.value);
		});
		
		let postDate = computed(()=> posts.postDate(post.value.created_at))
		


		function openFull() {
			f7.view.current.router.navigate(`/post/:${post.value.id}/`)
		}


			let gpttext = ref('')
			async function cl() {
				// fetch('/api/tic', {
				// 	method: 'POST',
				// 	headers: {
				// 		'Content-Type': 'application/json'
				// 	},
				// 	body: JSON.stringify({
				// 		color: state.settings.color.current,
				// 		nick: state.user.login
				// 	})
				// });


					ch.value=!ch.value


				app.dialog.prompt('введите', 'dwdwd', (val) => {
					const socket = new WebSocket('wss://unic.ichuk.ru/ws');
					socket.addEventListener('open', (event) => {
						console.log('Connected to server');
						socket.send(val);
						gpttext.value = '(';
					});
					socket.addEventListener('message', (event) => {
						let data = event.data;
						console.log(data);
						gpttext.value += data;
						// обновите ваш пользовательский интерфейс с использованием event.data
					});
					socket.addEventListener('close', (event) => {
						console.log('Disconnected from server');
						gpttext.value += ')';
					});
				});
			}

			async function registere() {
			async function req(type) {
			const response = await fetch('/api/start-registration/' + type, {
				method: 'POST',
				headers: {
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
				username: state.user.login
				})
			});
			const options = await response.json();

			// Преобразуйте настройки для использования с WebAuthn API
			options.user.id = Uint8Array.from(options.user.id, c => c.charCodeAt(0));
			options.challenge = Uint8Array.from(options.challenge, c => c.charCodeAt(0));
			options.pubKeyCredParams.forEach(param => {
				param.alg = parseInt(param.alg);
			});

			// Создание учетных данных
			f7.preloader.show()
			const credential = await navigator.credentials.create({ publicKey: options });
			f7.preloader.hide()
			// console.log(navigator.credentials.parseAuthData(credential))
			debugger
			console.log(options.challenge, credential.response.clientDataJSON)
			const betterCredential = {
				id: credential.id,
				rawId: btoa(credential.rawId),
				type: credential.type,
				response: {
				clientDataJSON: btoa(credential.response.clientDataJSON),
				attestationObject: btoa(credential.response.attestationObject)
				}
			};

			// Отправьте учетные данные обратно на сервер для проверки

			debugger
			const finishResponse = await fetch('/api/finish-registration', {
				method: 'POST',
				headers: {
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
				credential: betterCredential,
				user: {
					username: state.user.login,
				}
				}),
			});

			const verification = await finishResponse.json();

			if (verification.verified) {
				alert('succ')
				// Регистрация прошла успешно
			}
			}

			try {
			await req(1)
			} catch (error) {
			await req(0)
			}
		}

		async function authenticate() {
			const response = await fetch('/api/start-login', { method: 'POST' });
			const options = await response.json();

			// Преобразуйте настройки для использования с WebAuthn API
			options.challenge = Uint8Array.from(options.challenge, c => c.charCodeAt(0));
			options.allowCredentials.forEach(cred => {
			cred.id = Uint8Array.from(cred.id, c => c.charCodeAt(0));
			});

			// Получение учетных данных
			const assertion = await navigator.credentials.get({ publicKey: options });

			// Отправьте утверждение обратно на сервер для проверки
			const finishResponse = await fetch('/api/finish-login', {
			method: 'POST',
				headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(assertion),
			});

			const verification = await finishResponse.json();

			if (verification.verified) {
			alert('succ')
			}
		}

		return { post, content, postDate, openFull, gpttext, cl, registere, authenticate };
    },
    components: { Transition, DText, UserInList }
}

</script>