export function initDynamicShtorka() {
	let htmlEl = qs('html')
	let currentNavbarPos = 0
	let isModal = 0;
	let isSlowing = 0;
	let modalClosedAt = 0;
	let modalOpenedAt = 0;

	const dialogStackTime = 170
	let metaTheme = document.getElementById(app.device.ios ? 'iosTheme' : 'theme');
	let metaColor = ''
	let shtorkaEl = document.getElementById('shtorka');

	let currentNavbarC = window.getComputedStyle(document.body).getPropertyValue('--f7-bars-bg-color').trim()
	let pageBGHex = window.getComputedStyle(document.body).getPropertyValue('--f7-page-bg-color')
	let pageBGRGB = app.utils.colorHexToRgb(pageBGHex.trim())

	let colorsG = {
		simple: '--f7-bars-bg-color',
		modal: ['--f7-md-outline', '--f7-md-surface-1']
	}


	setTimeout(() => {
		if (app.photoBrowser?.get()) return 0
		app.emit('navbarLargeTransparentScroll', currentNavbarPos)
	}, 50);

	let oldColorDialog;
	let muta = new MutationObserver(function (ev) {
		let oldC = ev[0].oldValue
		let newC = htmlEl.classList


		

		let colors = []
		let steps = 24
		if (oldC == newC.value) return 0
		// debugger
		// console.log('muta')
		if (newC.contains('with-modal-dialog') || (newC.contains('with-modal-popup') && !app.photoBrowser?.get()) || newC.contains('with-modal-sheet') || newC.contains('with-modal-popover')) {
			if(isModal) return 0
			isModal = 1
			modalOpenedAt = +new Date
			
			// console.log(+new Date - modalClosedAt)
			if (+new Date - modalClosedAt > dialogStackTime) {
				oldColorDialog = metaTheme.getAttribute('content')
				if (oldColorDialog[0] == '#') {
					oldColorDialog = app.utils.colorHexToRgb(oldColorDialog)
				} else if (oldColorDialog[0] == 'r') {
					oldColorDialog = oldColorDialog.match(/\d+/g).map(Number);
				}
			} else {
				return 0
			}
			let rgb = rgbaToRgb([0, 0, 0, 0.4], oldColorDialog)
			// console.log('oldColorDialog', oldColorDialog)
			// console.log('newColor', rgbaToRgb([0, 0, 0, 0.4], oldColorDialog))
			colors = interpolateColors(oldColorDialog, rgb, steps)
			setThemeSlow(colors.length, 400/steps, colors)

		} else if (oldC.includes('with-modal-dialog') || (oldC.includes('with-modal-popup')) || oldC.includes('with-modal-sheet') || oldC.includes('with-modal-popover')) {
			isModal = 0
			// console.log('closing', +new Date - modalOpenedAt, +new Date - modalClosedAt)
			if (+new Date - modalOpenedAt <= dialogStackTime || (+new Date - modalClosedAt) - (+new Date - modalOpenedAt) <= dialogStackTime) {
				modalClosedAt = +new Date
				return 0
			}
			modalClosedAt = +new Date
			

			let from = rgbaToRgb([0, 0, 0, 0.435], oldColorDialog)
			colors = interpolateColors(from, oldColorDialog, steps)

			setThemeSlow(colors.length, 400 / steps, colors)
		}

	})
	window.muta = muta
	muta.observe(htmlEl, {
		attributes: true,
		attributeFilter: ['class'],
		attributeOldValue: true,
		subtree: false,
	})

	
	app.on('e-shadeShtorka', (opa, slow) => {
		let rgb = app.utils.colorHexToRgb(currentNavbarC)
		rgb.push((currentNavbarPos))
		let rgbNANav = rgbaToRgb(rgb, pageBGRGB)

		let rgbNA = rgbaToRgb([0, 0, 0, 1 - opa], rgbNANav)
		if (slow) {
			console.log('e-shadeShtorka')
			let ccol = (metaColor.indexOf('#') != -1 ? app.utils.colorHexToRgb(metaColor) : metaColor)
			if (ccol.length != 3) ccol = ccol.match(/\d+/g).map(Number)
			let colors = interpolateColors(ccol, rgbNA, 10)
			setThemeSlow(colors.length, 200 / 10, colors, false)
		} else {
			let rgbNAStr = `rgb(${rgbNA.join()})`
			metaTheme.setAttribute('content', rgbNAStr);
			metaColor = rgbNAStr
			if (shtorkaEl) {
				shtorkaEl.style.backgroundColor = rgbNAStr;
			}
		}

	})

	

	function setThemeColor(hex) {
		let color = window.getComputedStyle(document.body).getPropertyValue(hex).trim()
		if (metaTheme.getAttribute('content') == color) return 0
		metaTheme.setAttribute('content', color)
		metaColor = color
		if (shtorkaEl)
			shtorkaEl.style.backgroundColor = color
	}

	
	
	
	let lastNavbarBGChange = 0
	app.on('navbarLargeTransparentScroll', (ev) => {
		if(isModal) return 0
		
		currentNavbarPos = ev
		lastNavbarBGChange=+new Date
		let rgbNAStr = calcNavbarBg(ev)
		metaTheme.setAttribute('content', rgbNAStr)
		metaColor = rgbNAStr
		if (shtorkaEl)
			shtorkaEl.style.backgroundColor = rgbNAStr
	})
	function calcNavbarBg(ev, arr) {
		let rgb = app.utils.colorHexToRgb(currentNavbarC)
		rgb.push((ev))
		let rgbNA = rgbaToRgb(rgb, pageBGRGB)
		let rgbNAStr = `rgb(${rgbNA.join()})`
		return arr ?rgbNA:rgbNAStr
	}
	govno()
	q(document).on('page:beforein', function (e) {
		setTimeout(() => {
			govno(!!qs('.navbar-large-collapsed', e.target))
		}, 45);
	})
	q(document).on('page:tabshow', function (e) {
		setTimeout(() => {
			govno()
		}, 60);
	})
	setInterval(() => {
		
		govno()

	}, 300);
	function govno(hasBrightNavbar) {
		if (app.photoBrowser?.get()) return 0
		currentNavbarC = window.getComputedStyle(document.body).getPropertyValue('--f7-bars-bg-color').trim()
		pageBGHex = window.getComputedStyle(document.body).getPropertyValue('--f7-page-bg-color')
		pageBGRGB = app.utils.colorHexToRgb(pageBGHex.trim())

		let themeS = state?.settings?.lightTheme ?? ls()?.settings?.lightTheme ?? 'Системная'
		if (themeS == 'Системная') {
			if (app.device.prefersColorScheme() == 'light') {
				app.emit('e-themeChanged', 'light')
				htmlEl.classList.remove('dark')
			} else {
				app.emit('e-themeChanged', 'dark')
				htmlEl.classList.add('dark')
			}
		} else if (themeS == 'Светлая') {
			htmlEl.classList.remove('dark')
			app.emit('e-themeChanged', 'light')
		} else {
			htmlEl.classList.add('dark')
			app.emit('e-themeChanged', 'dark')
		}

		if (+new Date - lastNavbarBGChange < 60) return 0
		if (isSlowing) return 0

		// console.log('govno')
		lastNavbarBGChange = +new Date

		if (!isModal) {
			// console.log('nomodal')
			if ((hasBrightNavbar === false || !qs('@.tab-active .page-current .navbar-large-collapsed')) && hasBrightNavbar!==true) {
				setThemeColor('--f7-page-bg-color')
			} else {
				setThemeColor(colorsG.simple)
			}
		}
	}

	function setThemeSlow(steps, time, colors, tf) {
		isSlowing = 1
		console.time('ease')
		function ease(t) {
			const precision = 0.00001;
			const iterations = 20;

			function sampleCurve(a, b, c, t) {
				return ((a * t + b) * t + c) * t;
			}

			function sampleCurveDerivative(a, b, c, t) {
				return (3 * a * t + 2 * b) * t + c;
			}

			function solveCurveX(x) {
				let t0, t1, t2, x2, derivative, i;
				for (t2 = x, i = 0; i < iterations; i++) {
					x2 = sampleCurve(ax, bx, cx, t2) - x;
					if (Math.abs(x2) < precision) {
						return t2;
					}
					derivative = sampleCurveDerivative(ax, bx, cx, t2);
					if (Math.abs(derivative) < precision) {
						break;
					}
					t2 = t2 - x2 / derivative;
				}

				t0 = 0;
				t1 = 1;
				t2 = x;

				if (t2 < t0) {
					return t0;
				}
				if (t2 > t1) {
					return t1;
				}

				while (t0 < t1) {
					x2 = sampleCurve(ax, bx, cx, t2);
					if (Math.abs(x2 - x) < precision) {
						return t2;
					}
					if (x > x2) {
						t0 = t2;
					} else {
						t1 = t2;
					}
					t2 = (t1 - t0) * 0.5 + t0;
				}

				return t2;
			}

			const ax = 3 * 0 - 3 * 0.34 + 1;
			const bx = 3 * 0.34 - 6 * 0;
			const cx = 3 * 0;

			const ay = 3 * 0.8 - 3 * 1 + 1;
			const by = 3 * 1 - 6 * 0.8;
			const cy = 3 * 0.8;

			const x = solveCurveX(t);
			return sampleCurve(ay, by, cy, x);
		}

		function lerp(start, end, progress) {
			return start + (end - start) * progress;
		}

		function getColor(progress) {
			let index = Math.floor(progress * (colors.length - 1));
			let remainingProgress = (progress * (colors.length - 1)) - index;

			let startColor = colors[index];
			let endColor = index + 1 < colors.length ? colors[index + 1] : colors[index];

			return startColor.map((startComponent, i) => {
				return Math.round(lerp(startComponent, endColor[i], remainingProgress));
			});
		}
		

		for (let i = 0; i < steps; i++) {
			setTimeout(() => {
				let rgbStr = `rgb(${colors[i]})`;
				if (tf!==false) {
					let progress = ease(i / (steps - 1));
					rgbStr = `rgb(${getColor(progress).join(',')})`;
				}
				metaTheme.setAttribute('content', rgbStr);
				metaColor = rgbStr
				if (shtorkaEl) {
					shtorkaEl.style.backgroundColor = rgbStr;
				}

				if (!(i+1 < steps)) {
					setTimeout(() => {
						isSlowing = 0
					}, 40);
				}
			}, i * time);
		}
		
	}
}

function interpolateColors(rgb1, rgb2, steps) {
	const stepFactor = 1 / steps;
	const interpolatedColors = [rgb1];

	for (let i = 1; i < steps; i++) {
		const interpolatedColor = rgb1.map((channel, index) =>
			Math.round(channel + (rgb2[index] - channel) * stepFactor * i)
		);
		interpolatedColors.push(interpolatedColor);
	}

	interpolatedColors.push(rgb2);
	return interpolatedColors;
}

function rgbaToRgb(rgba, backgroundColor) {
	const alpha = rgba[3];
	const inverseAlpha = 1 - alpha;
	const result = [backgroundColor[0] * inverseAlpha + rgba[0] * alpha,
	backgroundColor[1] * inverseAlpha + rgba[1] * alpha,
	backgroundColor[2] * inverseAlpha + rgba[2] * alpha
	];
	return result.map(val => Math.round(val));
}

