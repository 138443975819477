<template>
	<a :href="'#'+href" draggable="false" :class="`tab-link ripple ripple-blur ${act?'tab-link-active':''}`">
		<i class="icon"><span class="msr">{{ icon }}</span></i>
		<span class="tabbar-label">{{name}}</span>
	</a>
</template>

<script setup>
	const props = defineProps(['href', 'icon', 'act', 'name'])

</script>