import { f7 } from "framework7-vue"
import strings from "./modules/strings";
import { useAppStore } from "./stores/appStore";
import { qs } from "./globalF";
import './libs/color-thief.min.js'
import * as backtrace from 'backtrace-js';
import SourceMappedStackTrace from 'sourcemapped-stacktrace'
import wpencode, { init as initEncode } from '@jsquash/webp/encode';

window.cacheName = 'workbox-precache-v2-https://unic.ichuk.ru/'
window.cacheLength = 179;
window.cacheWedth = 10.38;

const htmlEL = qs('html')
let htmlClasses = htmlEL.classList

export let domEventsExport;

export function initModules() {
	eviromentDetect()
	// keyboardSize()
	globalCacheListener()
	initLog()

	initEncode(null, {
		locateFile: (path, prefix) => `https://unic.ichuk.ru/ass/webp_enc.wasm`,
	})

	domEventsExport = domEvents()
}

export function eviromentDetect() {
	Object.defineProperty(window, 'env', {
		value: location.hostname == strings.app.url?'prod':'dev',
		writable: false
	});
return env
}

function keyboardSize() {

	let appCreatedTime = +new Date

	if (!f7.device.desktop) {
		let oldH = window.innerHeight
		let wasFocud = 0
		let lastTime = 0
		window.addEventListener("resize", (e) => {
			if (+new Date - lastTime < 350) return 0
			if (+new Date - appCreatedTime < 1000) return 0
			if (!qs('textarea') && !qs('input')) return 0
			console.log(+new Date - lastTime)
			lastTime = +new Date;
			let newH = window.innerHeight
			let isFocus = q('textarea:focus').length

			f7.emit('e-mresize', newH - oldH > 0)
			// alert(1)
			console.log((newH - oldH) >= 0, newH - oldH, wasFocud, isFocus)
			// debugger
			console.log(wasFocud)
			if (wasFocud) {
				wasFocud = 0
				document.body.classList.remove('keyboard')
				q('textarea:focus').blur()
				q('input:focus').blur()

			}
			else {

				document.body.classList.add('keyboard')
				wasFocud = 1
			}

		});
	}
}

function globalCacheListener() {
	if(window.env=='dev') return 0
	setTimeout(async () => {
		const appStore =useAppStore()
		if (appStore.versionUpdated) return 0
		const cache = await caches.open(window.cacheName);
		const cachedRequests = await cache.keys();
		const loadedResources = cachedRequests.length;
		const totalResources = window.cacheLength;
		const loadedPercentage = (loadedResources / totalResources) * 100;

		if (loadedPercentage != 100) {
			let inter = setInterval(async () => {
				const cache = await caches.open(window.cacheName);
				const cachedRequests = await cache.keys();
				const loadedResources = cachedRequests.length;
				const totalResources = window.cacheLength;
				const loadedPercentage = (loadedResources / totalResources) * 100;
				if (loadedPercentage == 100) {
					if (qs('#installApp')) {
						clearInterval(inter)
						return 0
					}
					window.app.toast.create({
						text: "Приложение успешно скачалось! Не забудьте заглянуть в настройки, чтобы установить.",
						closeTimeout: 4700,
						closeButton: 1,
						closeButtonText: "Перейти",
						on: {
							closeButtonClick() {
								qs('.tab-link-active').classList.remove('tab-link-active')
								qs('@.toolbar-inner a[href="#view-settings"]').click()
								setTimeout(() => {
									app.view.current.router.navigate('/settingsApp/')
								}, 10);
							}
						}
					}).open()
					clearInterval(inter)
				}
			}, 100)
		}
	}, 1);

	window.addEventListener('beforeinstallprompt', (e) => {
		e.preventDefault();
		window.deferredPrompt = e;
		// debugger



	});

}

function initLog() {
	window.backtrace = backtrace
	let client = backtrace.initialize({
		endpoint: 'https://ichuk.sp.backtrace.io:6098',
		token: '1f68bafc4a23bd9e1df1726b5ac7bc4380646ad9edec3aedfbfee4d38e2c1df1',
		disableGlobalHandler:false,
	});
	window.btc = client

	window.onerror = (err) => {
		console.log(1, err)
	}

	window.addEventListener('error', (ev)=>onerr(ev.error))
	// window.addEventListener('unhandledrejection', (ev) => onerr(ev.reason))

	async function onerr(event) {
		// debugger
			let error = event
			window.gerr = error
			console.log(error)
			const errorMessage = error.message;
		let errorStack = error.stack;
		// alert(JSON.stringify(error))
		// report(error)
			// SourceMappedStackTrace.mapStackTrace(errorStack, function (mappedStack) {
			// 	// Создаем объект для отправки на сервер
			// 	const errorData = {
			// 		message: errorMessage,
			// 		stack: mappedStack.join('\n')
			// 	};

			// 	const error = new Error(errorData.message);
			// 	error.stack = errorData.message + '\n' + errorData.stack;
			// 	window.terr = error
			// 	console.log(error)

			// 	report(error)
			// })
	}
	function report(error) {
		console.log('report', error)
		backtrace.report(error, {
			version: localStorage.version.split('^')[0],
			...flattenObject({ el: { cl: app.views.current.el.classList.toString(), id: app.views.current.el.id }, history: app.views.current.history.join(), id: app.views.current.id, page: { cl: qs('.page-current', qs('.tab-active')).classList.toString(), id: qs('.page-current', qs('.tab-active')).id } }, 'view'),
			...flattenObject(JSON.parse(localStorage.store), 'store')
		});
	}
	// report(new Error('init'))
}

function domEvents() {
	function iosClasses() { //error
		if (htmlClasses.contains('ios')) {
			htmlClasses.add('ios-translucent-bars')
			htmlClasses.add('ios-translucent-modals')
		} else {
			htmlClasses.remove('ios-translucent-bars')
			htmlClasses.remove('ios-translucent-modals')
			
		}
	}

	iosClasses()
	return {iosClasses}
}


function flattenObject(obj, prefix = '') {
	let result = {};

	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			let propName = prefix ? `${prefix}.${key}` : key;
			if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
				let nestedProps = flattenObject(obj[key], propName);
				result = { ...result, ...nestedProps };
			} else {
				result[propName] = obj[key];
			}
		}
	}

	return result;
}

export function waitUntil(cond, time) {
	return new Promise((resolve) => {
		const checkFont = () => {
			if (cond()) {
				resolve();
			} else {
				setTimeout(checkFont, time??20);
			}
		};

		checkFont();
	});
}

export async function avaColors(t) {
	let img = document.createElement('img');
	// img.setAttribute('src', `/img/avas/${t ? (t + '.webp') : state.user.ava.src}`)
	img.setAttribute('src', t ? t : `/img/avas/${state.user.ava.src}`)
	img.crossOrigin = "Anonymous";

	if (img.complete) {
		console.log('complete')
		return await calc()
	} else {
		// Здесь мы возвращаем новый Promise, который решается, когда изображение загружено
		return new Promise((resolve, reject) => {
			img.addEventListener('load', async function () {
				try {
					const result = await calc();
					resolve(result);
				} catch (error) {
					reject(error);
				}
			});
		});
	}

	async function calc() {
		let res = await ColorThief.prototype.getPalette(img)
		// debugger
		res = res.filter(el => el[0] + el[1] + el[2] > 200 && el[0] + el[1] + el[2] < 500)
		res = res.map(el => app.utils.colorRgbToHex(...el)).slice(0, 5)
		img.remove()
		return res
	}
}

export function validateStr(data) {
	let valid = true
	let trimmed = data.str.trim()
	if (data.minlen && trimmed.length < data.minlen) valid = `недостаточно символов. Минимум – ${data.minlen}`
	if (data.maxlen && trimmed.length > data.maxlen) valid = `слишком много символов. Максимум – ${data.maxlen}`
	if (valid !== true && data.name) {
		valid=`В ${data.name} ${valid}`
	} else if (valid !== true) {
		valid = valid[0].toUpperCase()+valid.slice(1)
	}
	console.log(valid)
	return valid
}
let webpl
export function handleImg(event, type = 'blob', width = 256, q = 0.5) {
	let file;
	if (event instanceof Blob) {
		file = event
	} else {
		file = event.target.files[0]; // Получаем выбранный файл
	}

if (file) {
	return new Promise(async (resolve) => {
		if (file instanceof Blob && 0) {
			await initEncode(null, {
				locateFile: (path, prefix) => `https://unic.ichuk.ru/ass/webp_enc.wasm`,
			})
			let enc = wpencode
			const blobUrl = URL.createObjectURL(file);

			const img = new Image();
			img.onload = async function () {
				// Создаем холст размером с изображение
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				// Получаем контекст холста
				const ctx = canvas.getContext('2d');
				// Рендерим изображение на холсте
				ctx.drawImage(img, 0, 0);
				// Получаем ImageData
				const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
				// Теперь у вас есть imageData, который можно использовать как нужно
				let ar = await enc(imageData, {
					method: 6,
					pass: 5,
					quality:60,
				})
				let blob = new Blob([ar], { type: 'image/webp' });

				console.log(blob, URL.createObjectURL(blob));
				// Освобождаем созданный URL, чтобы избежать утечек памяти
				URL.revokeObjectURL(blobUrl);
			};

			img.src = blobUrl;
			console.log(webpl, enc)
		} else {
			const reader = new FileReader(); // Создаем объект FileReader для чтения содержимого файла
			reader.onerror = (e) => {
				// Выводим информацию об ошибке в консоль
				console.error('FileReader error:', e.target.error);
			};

			reader.readAsDataURL(file); // Читаем содержимое файла как data URL
			reader.onload = (e) => {
				const imgSrc = e.target.result; // Получаем содержимое файла в виде data URL

				// Создаем объект Image
				const img = new Image();
				img.src = imgSrc;
				img.onload = async () => {
					const aspectRatio = img.width / img.height;
					let newWidth = img.width; // Новая ширина, которую вы хотите установить
					if (newWidth > width) {
						newWidth = width;
					}
					const newHeight = newWidth / aspectRatio; // Вычисляем новую высоту с сохранением пропорций
					const canvas = document.createElement('canvas');
					canvas.width = newWidth;
					canvas.height = newHeight;

					const ctx = canvas.getContext('2d');
					ctx.drawImage(img, 0, 0, newWidth, newHeight);
					const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
					// Теперь у вас есть imageData, который можно использовать как нужно
					let ar = await wpencode(imageData, {
						method: 6,
						pass: 3,
						quality: q*100,
						target_size: 60000,
					})
					let blob = new Blob([ar], { type: 'image/webp' });

					// console.log(blob, URL.createObjectURL(blob));
					// Освобождаем созданный URL, чтобы избежать утечек памяти
					// URL.revokeObjectURL(blobUrl);
					resolve(blob);
					return 0
					// canvas.toBlob((blob) => {
					// 	if (type == 'blob') {
					// 		resolve(blob);
					// 	} else if (type == 'base64') {
					// 		const newReader = new FileReader(); // Создаем новый экземпляр FileReader

					// 		newReader.onloadend = () => {
					// 			const base64data = newReader.result; // Строка в формате Base64
					// 			resolve(base64data);
					// 		};

					// 		newReader.readAsDataURL(blob); // Читаем сжатый Blob для преобразования в Base64
					// 	}
					// }, 'image/webp', q);
				};
			}
		}
		
	})
	
}
}