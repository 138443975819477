<template>
    <f7-page name="home" :page-content="0">
      <Heading />
      <Fab />
      <f7-page-content ref="pagec" ptr ptr-distance="90" class="homePtr">
         <div id="telegramWidget"></div>
        <Feed/>
      </f7-page-content>
    </f7-page>
  
  
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue';
import Fab from '../components/home/fab.vue';
import Feed from '../components/home/feed/feed.vue';
import Heading from '../components/home/heading/heading.vue';
import { f7 } from 'framework7-vue';

function auth(d) {
  console.log(d)
}
window.auth = auth;

let pagec = ref(null)

f7.on('e-homeRefresh', () => {
  app.emit('e-homeRefreshed')
})

onMounted(() => {
  nextTick(() => {
    app.ptr.get(pagec.value.$el).on('refresh', async (el, done) => {
      app.emit('e-homeRefreshed')
    })
  })
})

// onMounted(() => {
//   let script = document.createElement('script');
//   script.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?22');
//   script.setAttribute('async', '');
//   script.setAttribute('data-telegram-login', 'unicAuthBot');
//   script.setAttribute('data-size', 'medium');
//   script.setAttribute('data-onauth', 'auth(user)');
//   script.setAttribute('data-request-access', 'write');

//   // Поместите скрипт в определенное место в документе
//   document.getElementById('telegramWidget').parentElement.appendChild(script);
// })

</script>