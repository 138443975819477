import localforage from "localforage";
import { setLS } from "../stores/storeUtils";

export async function auth(login, pass, email, err) {
	let req = await fetch('/api/auth', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			login: login.value,
			pass: pass.value,
			email:email.value
		})
	});

	let json = await req.json()
	console.log(json)
	if (json.message == 'no compare') {
		err.value = 'no compare'
	} else if (json.message == 'repeat email') {
		err.value = 'repeat email'
	} else if (json.message == 'compare') {
		if (!json.dada) json.dada = {user:{}}
		json.dada.user.hash = json.hash
		json.dada.user.login = login.value
		localStorage.store = JSON.stringify(json.dada)
		localStorage.sync_time=json.sync_time
		good()
	} else if (json.message == 'created') {
		let ls = JSON.parse(localStorage.store)
		ls.user.hash = json.hash
		ls.user.login = login.value
		// debugger
		localStorage.store=JSON.stringify(ls)
		good()
	}

	function good() {
		app.dialog.preloader('Получилось! Входим..')
		location.reload()
	}

}

export async function serverSync() {
	let sstore = ls()
	delete sstore.app
	delete sstore.posts
	let req = await fetch('/api/userSync', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			sync_time: localStorage.sync_time,
			login: state.user.login,
			hash: state.user.hash,
			dada: sstore
		})
	});


	let json = await req.json()
	if (json.message == 'is new') {
		localStorage.store = JSON.stringify(json.dada)
		localStorage.sync_time = json.sync_time
		localforage.setItem('store', localStorage.store)
		setTimeout(() => {
			// debugger
			location.href = location.origin
		}, 100)
	}
}