import { defineStore } from 'pinia'
import { ref } from 'vue'
import { initLS, setLS } from './storeUtils'

export const useNotificationsStore = defineStore('notifications', () => {
	const items = ref(ls()?.notifications?.items ?? [])
	initLS('notifications', []);
	initLS('notifications.items', []);
	initLS('components')

	function addItem(data) {
		if(items.value.length) items.value.length = 0
		else items.value.push(data)
		setLS('notifications.items', items.value)
	}

	setInterval(() => {
		// addItem({})
	}, 5000);

	return {items, addItem}
})