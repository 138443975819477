<template>
  <a href="/avaChange/" class="ava oh ripple ripple-blur ripple-color-primary">
	<img :src="store.getAva" class="circle">
  </a>
</template>

<script setup>
import { useUserStore } from "../../../js/stores/userStore";

const store = useUserStore()

</script>

<style lang="less" scoped>
@import url('./ava.less');
</style>