<template>
	<!--
        Tabbar for switching views-tabs. Should be a first child in Views.
        Additional "toolbar-bottom" class to set it on bottom
      -->
	<div class="toolbar tabbar toolbar-bottom tabbar-icons">
		<div class="toolbar-inner">
			<Tab v-for="tab of tabs" :key="tab.href"
				:href="tab.href" :icon="tab.icon" :act="tab.act" :name="tab.name"/>
		</div>
	</div>
</template>

<script setup>
import './tabbar.less'
import Tab from './tab.vue';

const tabs = [
	{
		href: 'view-home',
		icon: 'chair',
		act:1,
		name:'Дом',
		
	},
	{
		href: 'view-catalog',
		icon: 'groups',
		act:0,
		name:'Группы',
	},
	{
		href: 'view-settings',
		icon: 'settings',
		act:0,
		name:'Настройки',
	}
]

</script>