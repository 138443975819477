import nTap from '../../assets/sounds/nTap.mp3'
import toast from '../../assets/sounds/toast.mp3'
import notification from '../../assets/sounds/notification.mp3'
import toggle from '../../assets/sounds/toggle.mp3'
import error from '../../assets/sounds/error.mp3'
import succ from '../../assets/sounds/succ.mp3'
import wow from '../../assets/sounds/wow.mp3'
import { useSettingsStore } from './../stores/settingsStore';

let lastSoundTime = 0;
let settings;
export function initSoundEngine() {
	settings = useSettingsStore()
	// q(document).on('touchstart', '.ripple, .ripple-blur, label, a, .dialog-button', (event) => {
	// 	if ([...event.target.classList].some(el => ['toggle-icon'].includes(el)) || event.target.closest('.bigToggle') || event.target.closest('label.toggle')) return 0
	// 	setTimeout(() => {
	// 		play(nTap, settings.soundConfig.taps / 1.1)
	// 	}, 0);
	// })

	// app.on('touched', (target) => {
	// 	// deb
	// 	if ([...target.classList].some(el => ['toggle-icon'].includes(el)) || target.closest('.bigToggle') || target.closest('label.toggle')) return 0
	// 	if ([...target.classList].some(el => ['ripple', 'ripple-blur'].includes(el)) || target.closest('label') ||target.closest('a')) {

	// 		setTimeout(() => {
	// 			play(nTap, settings.soundConfig.taps / 1.1)
	// 		}, 0);
	// 	}
	// })

	app.on('rippleStart', (el) => {
		if ([...el[0].classList].some(el => ['toggle-icon'].includes(el)) || el[0].closest('.bigToggle') || el[0].closest('label.toggle')) return 0
		setTimeout(() => {
			play(nTap, settings.soundConfig.taps / 1.1)
		}, 0);
	})

	app.on('e-toggleChanged', () => {
		play(toggle, settings.soundConfig.taps/1.5)
	})

	app.on('e-error', (v) => {
		play(error, settings.soundConfig.notifications*(v??1))
	})

	app.on('e-succ', (v) => {
		play(succ, settings.soundConfig.notifications*(v??1))
	})

	app.on('toastOpen', (ev) => {
		if(ev.params.cssClass=='error') return 0
		if (ev.params.quiet) return 0
		play(toast, settings.soundConfig.notifications/1)
	})
	
	app.on('notificationOpen', () => {
		play(notification, settings.soundConfig.notifications)
	})

	app.on('wow', () => {
		setTimeout(() => {
			play(wow, settings.soundConfig.notifications)
		}, 30);
	})
	
}

function getVolume(v) {
	const minVolume = 0.01; // минимальная громкость
	const maxVolume = 1; // максимальная громкость
	const volumePercentage = v / 100;

	// Преобразование громкости с использованием логарифмической шкалы
	const logVolume = minVolume * Math.pow(maxVolume / minVolume, volumePercentage);
	return logVolume
}

function shoulPlay() {
	return +new Date - lastSoundTime > 200 && settings.sound
}

function play(sound, config) {
	// console.log(sound)
	// debugger
	if (!shoulPlay()) return false
		let auduio = new Audio(sound)
		auduio.volume = getVolume(config)
		auduio.play()
}