<template>
	<a :href="`${!static?`/settingsAccount/${pr.user.nick}/`:''}`" class="user ripple ripple-blur uil">
		<!-- <div class="ripple-wave cus"></div> -->
		<div class="left">
			<img class="ava" :class="user.role" :src="avasrc"/>
			<div class="names">
				<div class="name" :style="`--ncolor:${pr.user.color};`"><DText :text="pr.user.name"/></div>
				<div class="nick"><span class="t">@{{ pr.user.nick }}</span> <span v-if="user.role" :class="`role ${user.role}`">{{ rolesToRus[user.role] }}</span></div>
			</div>
		</div>
		<div class="right">
			<slot name="right"></slot>
		</div>
	</a>
</template>

<script setup>
import { computed } from 'vue';
import DText from '../ui/dText.vue';

const rolesToRus = {
	admin:'админ',
}



let pr = defineProps(['user', 'imadmin', 'static'])
let ava = computed(() => {
	if (!pr.user.nick) {
		return { src: '' }
	} else {
		return pr.user.ava.indexOf('{') != -1 ? JSON.parse(pr.user.ava) : { src: pr.user.ava }
	}
})
let avasrc = computed(() => {
	if (ava.value.src.split('?').length > 1) {
		return `https://storage.yandexcloud.net/unicimages/${ava.value.src}`
	} else {
		return `/img/avas/${ava.value.src}.webp`
	}
})

function dits(ev) {
ev.stopPropagation();

	console.log('dits')
}
</script>

<style lang="less">
// .ripple-wave{
// 	//     contain: content;
//     // width: var(--pwidth);
//     // height: var(--pheight);
//     // margin-top: var(--pmt);
//     // margin-left: var(--pml);
//     // left: var(--pleft);
//     // top: var(--ptop);

// 	    contain: content;
//     width: 100px;
//     height: 100px;
//     /* margin-top: var(--pmt); */
//     /* margin-left: var(--pml); */
//     left: var(--pleft);
//     top: var(--ptop);
//     z-index: 5;
	
// }
.ulist{
	 --padding: 0.6rem 1rem;
--avaSize: 4rem;
}

	.user.uil{
display: flex;
    gap: 0.9rem;
    align-items: center;
    padding: var(--padding);
	position: relative;
	overflow: hidden;
	contain: content;
	// translate: 0px 0px 1px;
	color:var(--f7-block-text-color);
	will-change: contents;
	transition: all 0.7s;
	justify-content: space-between;
	// background-color: black;

	// &:active{
	// 	scale: 2;
	// }

	.left{
	display: flex;
	gap: 0.9rem;
    align-items: center;
}

.ava{
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	object-fit: cover;

	// &.admin{
	// 	filter: drop-shadow(1px 1px red);
	// }
}

.names{
    display: flex;
    flex-direction: column;
}

.name{
	font-size: 1.1em;
    font-weight: 500;
	color: var(--ncolor);
	filter: brightness(0.6);
	html.dark &{
		filter: brightness(1.4);
	}
}

.nick{
	.t{
		opacity: 0.7;
	}
	.role{
		padding: 0.08rem 0.5rem;
		border-radius: 1.3rem;
		margin-left: 3pt;
		&.admin{
			background-color: #ffc300;
			color: black;
		}
	}
}
}



</style>