import { useAppStore } from "./stores/appStore";
import { setLS } from "./stores/storeUtils";

const hasYM = 0;

export function s_check_version(ms) {

  function doo() {
    if (navigator.onLine) {
      fetch('/api/version')
        .then((response) => response.text())
        .then((data) => {
          const res = data;
          if (data.includes('nginx')) return 0
          if (data.includes('Пошёл нахуй')) {
            debugger
            // location.replace(location.origin)
            return 0
          }
          // console.log(res)
          if (localStorage.version == undefined) {
            localStorage.version = res

          } else if (res != localStorage.version && res[0] != 'b') {
            // debugger
            localStorage.version = res
            const appStore = useAppStore()
            appStore.versionUpdated = 1;
            setLS()
            clearCache()


            let resArr = res.split('^')
            let wnew = () => {
              if (resArr.length > 1) {
                return '</br>' + resArr.splice(1).map((it) => {
                  return `• ${it}`
                }).join('</br>') + '</br>-------'
              }
              return ''
            }

            if (location.hostname != 'localhost' && hasYM) ym(88147894, 'params', { version: resArr[0] })

            app.dialog.alert(`Обнаружена новая версия приложения <strong>` + resArr[0] + `</strong>.
            ${resArr.length > 1 ? '</br>-------</br>Что нового:' : ""}
            ${wnew()}
            </br> Страница будет перезагружена`, 'Обновление', () => {
              location.replace(location.origin)
              location.replace(location.origin)
              location.replace(location.origin)
              for (let i = 0; i < 10; i++) {
                setTimeout(() => {
                  // debugger
                  location.reload(true)
                }, i * 100);
              }
            })
          } else if (res[0] == 'b') {
            localStorage.version = res
            // app.dialog.alert('Простите небольшие технические шоколадки')
          }
        })
    }
  }

  doo()
  
 setInterval(() => {
   doo()
 }, ms);
  

}

export function clearCache() {
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  console.log("CLEEEARRR")
  try {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName);
      });
    });
  } catch (error) { }

  try {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
  } catch (error) { }
}