import { useSettingsStore } from './../stores/settingsStore';

export function initVibrateEngine() {
	console.log('vibr')
	let settings = useSettingsStore()
	q(document).on('click', '.ripple, .ripple-blur, label, a, .dialog-button, button', (event) => {
		setTimeout(() => {
			gf.vibrate('tap', settings.vibro.n)
		}, 5);
	})

	app.on('e-toggleChanged', () => {
		gf.vibrate([200], settings.vibro.n)
	})

	app.on('e-error', (v) => {
		gf.vibrate('fail', settings.vibro.nn)
		// play('', settings.soundConfig.notifications*(v??1))
	})

	app.on('e-succ', (v) => {
		gf.vibrate('lil success', settings.vibro.nn)
		// play(succ, settings.soundConfig.notifications*(v??1))
	})
	
	app.on('toastOpen', async () => {
		gf.vibrate('lil success', settings.vibro.nn)
		flashNotification([300]);
		// play(toast, settings.soundConfig.notifications/1)
	})
	app.on('rangeChange', () => {
		gf.vibrate('step', settings.vibro.n)
	})
	
	app.on('notificationOpen', () => {
		gf.vibrate('success', settings.vibro.nn)

		flashNotification([300, 200, 300]);
		// play(notification, settings.soundConfig.notifications)
	})
	
	app.on('wow', () => {
		gf.vibrate('big success', settings.vibro.nn)
		// play(wow, settings.soundConfig.notifications)
	})
	
}
async function flashNotification(intervals) {
	return 0
  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const torchState = i % 2 === 0 ? "on" : "off";

    await toggleFlashlight(torchState);
    await new Promise((resolve) => setTimeout(resolve, interval));
  }

  // Выключаем фонарик, если он остался включенным
  if (intervals.length % 2 !== 0) {
    await toggleFlashlight("off");
  }
}

window.flash2 = toggleFlashlight
let torch = false;
let track;
async function toggleFlashlight(action) {
	if (action === "on" || action === "off") {
		const desiredTorchState = action === "on";
		if (torch === desiredTorchState) {
			return torch;
		}

		if (desiredTorchState && !track) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: { facingMode: "environment" },
				});
				track = stream.getVideoTracks()[0];
			} catch (error) {
				console.error("Error:", error);
				return false;
			}
		}

		try {
			await track.applyConstraints({
				advanced: [{ torch: (torch = !torch) }],
			});

			if (!torch) {
				track.stop();
				track = null;
			}

			return torch;
		} catch (error) {
			console.error("Error:", error);
			return false;
		}
	} else if (action === "check") {
		if (!navigator.mediaDevices) {
			return false;
		}

		const devices = await navigator.mediaDevices.enumerateDevices();
		const cameras = devices.filter((device) => device.kind === "videoinput");
		const environmentCamera = cameras.find(
			(camera) => camera.label.toLowerCase().includes("back")
		);

		return !!environmentCamera;
	} else {
		throw new Error("Invalid action. Use 'on', 'off' or 'check'.");
	}
}
