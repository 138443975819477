
import Framework7, { utils, getDevice, createStore } from 'framework7/lite';
import Tooltip from 'framework7/components/tooltip';
import Popup from 'framework7/components/popup';
import Toggle from 'framework7/components/toggle';
import Input from 'framework7/components/input';
import Toast from 'framework7/components/toast';
import Dialog from 'framework7/components/dialog';
import Tabs from 'framework7/components/tabs';
import PullToRefresh from 'framework7/components/pull-to-refresh';
import Preloader from 'framework7/components/preloader';
import range from 'framework7/components/range';
import notification from 'framework7/components/notification';
import Accordion from 'framework7/components/accordion';
import SheetComponent from 'framework7/components/sheet';
import ColorPickerComponent from 'framework7/components/color-picker';
import ProgressbarComponent from 'framework7/components/progressbar';
import SearchbarComponent from 'framework7/components/searchbar';

Framework7.use([
Tooltip,
Popup,
Toggle,
Input,
Toast,
Dialog,
	Tabs,
	PullToRefresh,
	notification,
	range,
	Preloader,
	Accordion,
	SheetComponent,
	ColorPickerComponent,
	ProgressbarComponent,
	SearchbarComponent

]);

export default Framework7;
export { utils, getDevice, createStore };
