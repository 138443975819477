<template>
	<div class="user uil ripple ripple-blur" @click="invite">
	<div class="left">
		<div class="ava">
			<span class="msr">person_add</span>
		</div>
				<div class="names">
					<div class="name">Пригласить </div>
					<div class="nick">человека в группу</div>
				</div>
	</div>
	</div>
</template>

<script setup>
let pr = defineProps(['group'])
async function invite() {

	let req = await fetch('/api/createGroupInvite', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			login: state.user.login,
			hash: state.user.hash,
			name: pr.group.name,
			code: pr.group.code,
		})
	})
	let data = await req.json()
	let status = data.status
	if (status == 'early invite') {
		app.emit('e-error')
		const date = new Date(+new Date() + ((1000 * 60 * 60 * 12) - data.data));
		const options = { day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit', hour12: false };
		let dateStr = new Intl.DateTimeFormat('ru-RU', options).format(date);
		app.dialog.alert(`Чтобы приглашать в группу, нужно находиться в ней минимум 12 часов. Вы сможете ${dateStr}`, 'Подожди')
		return 0
	}
	if(!data.data.invite_code) return 0
	window.dataTransfer = {
		length: 10,
		dataName: 'приглашения',
		code: data.data.invite_code 
	}

	app.views.current.router.navigate('/dataTransfer/send/lupa/')
}
</script>

<style lang="less" scoped>
.ava{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
    border-style: dashed;
    opacity: 0.9;
    font-size: 18pt;
    box-sizing: border-box;

	.msr{
		    translate: 6% 0%;
font-size: 23pt;
	}
}
</style>