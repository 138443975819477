import { ref } from "vue";
import { deepUnref } from "../libs/deepUnref";
import localforage from "localforage";
import { serverSync } from "../modules/auth";

export function initLS(key, def) {
	// debugger
	if (def === undefined) def = {}
	if ([undefined, null].includes(eval(`ls().${key}`))) {
		let lsStore = ls()
		try {
			eval(`lsStore.${key} = def;`);
		} catch (error) {
			let keys = key.split('.')
			let cstr=''
			for (let i = 0; i < keys.length; i++) {
				const el = keys[i];
				cstr+='.'+el
				let str = `lsStore${cstr}`
				if (!eval(str)) {
					eval(str+'={}')
				}
				
			}
			eval(`lsStore.${key} = def;`);
		}
		localStorage.store = JSON.stringify(lsStore)
	}
}

export function initField(path, def) {
	initLS(path, def)
	// console.log(path,ls(path))
	return ref(ls(path) ?? def)
}

let savedTime = 0;
let saveTimer;
let needToSave = false;
export function setLS() {
	// debugger
	// console.time('unref')
	needToSave=true
	
}

setInterval(() => {
	if (needToSave && (+new Date() - savedTime > 2000)) {
		needToSave = false;
		savedTime = +new Date()

		let tState = deepUnref(window.state)
		// console.timeEnd('unref')
		// console.time('json')
		// let tState = JSON.parse(JSON.stringify(window.state))
		// console.timeEnd('json')
		const fieldsToRemove = ['ldisc', 'title', 'sdisc', 'how', 'rare', 'comp', 'img'];
		delete tState.settings.color.palette
		
		const newArray = tState.cards.cards.items.map((item) => {
			const newItem = { ...item };
			fieldsToRemove.forEach((field) => {
				delete newItem[field];
			});
			return newItem;
		});
		tState.cards.cards.items = newArray
		delete tState.posts
		let jsrtcut = JSON.stringify(tState)
		if (jsrtcut == localStorage.store) {
			return 0
		}
		localStorage.store = jsrtcut
		console.log('🏪 Saved')
		// console.log(localStorage.store)
		localStorage.sync_time = BigInt(localStorage.sync_time)+1n

		localforage.setItem('store', tState)
		serverSync()
	}
}, 1000);

async function saveServ() {
	let req = await fetch('/api/userSave', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			login: state.user.login,
			hash: state.user.hash,
			dada: ls()
		})
	});

}

export function setLSc(key, def) {
	// debugger
	let lsStore = ls()
	// debugger
	eval(`lsStore.${key} = def;`);
	localStorage.store = JSON.stringify(lsStore)
	console.log('saveserv')
	localStorage.sync_time =BigInt(localStorage.sync_time)+1n
	serverSync()

	localforage.setItem('store', localStorage.store)
}