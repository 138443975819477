<template>
	<div class="ulist">
		<slot name="before"></slot>
		<UserInList v-for="user in cusers" :user="user" :key="user.nick" :imadmin="imadmin"/>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import UserInList from './userInList.vue';
import { useUserStore } from '../../js/stores/userStore';
let pr = defineProps(['users'])
let ustore = useUserStore()
let cusers = computed(() => {
	let na = pr.users.toSorted((a, b) => {
		// Сначала проверяем, соответствует ли nick логину пользователя
		if (a.nick === ustore.login) return -1;
		if (b.nick === ustore.login) return 1;

		// Потом проверяем, есть ли у пользователей роль
		if (a.role !== null && b.role === null) return -1;
		if (a.role === null && b.role !== null) return 1;

		// Наконец, сортируем по убыванию алфавита
		return b.nick.localeCompare(a.nick);
	});
	return na
})
let imadmin = computed(() => {
	let admin = cusers.value.find(el => el.role == 'admin')
	if (admin == null) return 0
	if (admin.nick == ustore.login) return 1;
})

</script>

<style lang="less" scoped>

</style>