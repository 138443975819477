import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { initLS, setLSc } from './storeUtils'
import { useUserStore } from './userStore'
import { useSettingsStore } from './settingsStore';
import './../libs/RussianNouns.js';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
const rusCh = ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь', 'э', 'ю', 'я'];
export const useCardsStore = defineStore('cards', () => {
  const us = useUserStore()
  const ss = useSettingsStore()

  window.sk = RussianNouns

  const lsitems = ref(ls()?.cards?.lsitems??[])

  function updateTimeOnCondChange(card) {
    watch(card.cond, (newVal, oldVal) => {
      if (newVal && +new Date - window.timeInited>1500) {
        // debugger
        let t = oldVal
        card.time = +new Date
        lsitems.value[card.id]=card.time
        setLSc(`cards.lsitems[${card.id}]`, card.time)
        document.body.style.pointerEvents='none'
        setTimeout(() => {
          app.emit('wow')
          window.popupProps = {
            type: card
          }
          app.view.current.router.navigate(`/achive/${card.comp}/`)
          document.body.style.pointerEvents = ''
        }, 1000);
      } else if (+new Date - window.timeInited > 1500) {
        // debugger
      }
    }, { immediate: true })
  }
  
  const c = (v) => computed(v)
  const isInstall = ref(ls()?.cards?.lsitems?.[2] ?? 0);
  // debugger
  if (!isInstall.value) {
    setTimeout(() => {
      isInstall.value = (window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone === true)
    }, 1700);
  }

  const lstime = (key)=>eval(`ls()?.cards?.lsitems?.[${key}]`)

  let cards = ref({
    items: [
      {
        id: 0,
        rare:0,
        title: computed(() => us.name?.length > 2?`Членская карта ${rod(us.name)}`:"эмм.."),
        sdisc: c(()=>"Добро пожаловать в «Уник»"),
        ldisc: "Самая ценная коллекционная карточка, ваш пропуск в мир этого замечательного приложения",
        cond: computed(() => us.name?.length > 2),
        comp: "cardMember",
        how: "Представьтесь",
        img:'member0.8',
        time: ref(lstime(0)??0)
      },
      {
        id: 2,
        rare:0,
        title: computed(() => "Полная нативность"),
        sdisc: c(()=>"Идеальный UX"),
        ldisc: "Приложение рассчитывалось как раз под тот формат, к которому вы пришли",
        cond: c(() => isInstall.value),
        comp: "cardMember",
        how: "Установитесь",
        img:'install1.0',
        time: ref(lstime(2)??0)
      },
      {
        id: 4,
        rare:0,
        title: computed(() => us.name?.length > 2?`Блбала ${rod(us.name)}`:"эмм.."),
        sdisc: "Добро пожаловать в «Уник»",
        ldisc: "Самая ценная коллекционная карточка, ваш пропуск в мир этого замечательного приложения",
        cond: computed(() => 0),
        comp: "cardMember",
        how: "А никак",
        img:'member0.8',
        time: ref(lstime(4)??0)
      },
      {
        id: 1,
        rare:1,
        title: computed(() => "RGB приложение"),
        sdisc: c(()=>"Тяжёлый выбор оформления"),
        ldisc: computed(() => `Когда-нибудь ${us.name} определится с цветом..`),
        cond: computed(() => ss.color.sets.length>=3),
        comp: "rare1",
        how: "Попробуйте разные цвета",
        img: 'rgb1.0',
        time: ref(lstime(1)??0)
      },
      {
        id: 5,
        rare:1,
        title: computed(() => "Pro-мод"),
        sdisc: c(() => `${us.name} и сам своего рода разработчик`),
        ldisc: computed(() => `${us.name} отрыл самые глубокие настройки`),
        cond: computed(() => ss.proSettings.unlocked),
        comp: "rare1",
        how: "Какая уже версия?",
        img: 'proMode1.0',
        time: ref(lstime(5)??0)
      },
    ].map(card => {
      updateTimeOnCondChange(card)
      return card
    })
  })

  const getCards = () => cards;

  initLS('cards')
  initLS('cards.lsitems', [])


  return {
    lsitems, cards
  }
})



function rod(text) {
  // if (rusCh.includes(text.at(-1)))
    return text.split(' ').map(el => {
      return rusCh.includes(el.at(-1)) ? new sk.Engine().decline({
        text: el,
        gender: 'общий'
      }, 'родительный')[0] : el
    }).join(' ')
  // else return text
}
function vinitel(text) {
  // if (rusCh.includes(text.at(-1)))
    return text.split(' ').map(el => {
      return rusCh.includes(el.at(-1)) ? new sk.Engine().decline({
        text: el,
        gender: 'общий'
      }, 'винительный')[0] : el
    }).join(' ')
  // else return text
}

window.rod=rod
window.vinitel=vinitel