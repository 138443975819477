<template>
	<!-- <div class="navbar heading">
		<div class="navbar-bg"></div>
		<div class="navbar-inner sliding">
			<div class="title">
				<wish />
			</div>
			<div class="right">
				<Bell/>
			</div>
		</div>
	</div> -->
	<f7-navbar large transparent class="heading">
		<template #right>
			<Bell />
			<a class="link icon-only searchbar-enable ripple-inset" href="#" data-searchbar=".searchbar-demo">
				<span class="msr">search</span>
			</a>
		</template>
		<template #title>
			<wish :short="1" />
		</template>
		<template #title-large>
			<wish :short="0"/>
		</template>
		<f7-searchbar ref="searchbar"
	        class="searchbar-demo"
	        expandable
			placeholder="Поиск постов"
	        search-container=".search-list"
	        search-in=".item-title"
			v-model:value="searchQuery"
	      >
			<f7-segmented slot="after-inner" strong class="abs">
	        <f7-button :active="activeStrongButton === 0" @click="activeStrongButton = 0"
	          >Заголовок</f7-button
	        >
	        <f7-button :active="activeStrongButton === 1" @click="activeStrongButton = 1"
	          >Содержимое</f7-button
	        >
	        <f7-button :active="activeStrongButton === 2" @click="activeStrongButton = 2"
	          >Автор</f7-button
	        >
	      </f7-segmented>
		</f7-searchbar>

	</f7-navbar>
	
</template>


<script setup>
import './heading.less'
import wish from "./wish.vue";
import Bell from './bell.vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { usePostsStore } from '../../../js/stores/postsStore';
let pstore = usePostsStore()
let searchQuery = ref('')
let debounceTimeout
let controller;
let searchbar = ref(null)
let activeStrongButton = ref(0)
watch(searchQuery, (v) => {
	if(v.trim().length<3) return 0
	clearTimeout(debounceTimeout)
	debounceTimeout = setTimeout(() => {
		search(v)
	}, 400);
})

onMounted(() => {
	console.log(searchbar)
	searchbar.value.$el.addEventListener('searchbar:disable', () => {
		pstore.getPostsLF()
	})
})

const searchTypes = ['title', 'content', 'author']
async function search(v) {
	console.log(v)
	let text = v.trim().slice(0,30)
	if (controller) {
		controller.abort();
	}
	controller = new AbortController();
	const signal = controller.signal;
	let queryParams = new URLSearchParams({
		login: state.user.login,
		group: state.user.group.id,
		text: text,
		scope: searchTypes[activeStrongButton.value]??'title'
	}).toString();

	const headers = {
		Authorization: `Bearer ${state.user.hash}`
	};

	let req = await fetch(`/api/searchTask?${queryParams}`, {
		method: 'GET',
		headers: headers,
		signal
	});
	if (req.status == 404) {
		app.emit('e-searchCompleted', [])
	} else if(req.status==200) {
		let data = await req.json()
		app.emit('e-searchCompleted', data)
	}
}
</script>