import { f7 } from "framework7-vue"

export function groupsInit() {
	listenJoinCode()
}

function listenJoinCode() {
	let code = app.utils.parseUrlQuery()?.join
	let lsCode = localStorage.findJoinCode
	if (code) {
		localStorage.findJoinCode = code
		document.location.href = document.location.origin
	}
	else if (lsCode) {
		setTimeout(() => {
			f7.view.current.router.navigate(`/joinGroup/${lsCode}/`)
			delete localStorage.findJoinCode
		}, 300);
	}
}