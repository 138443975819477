import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { initField, initLS, setLS, setLSc } from './storeUtils'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAppStore = defineStore('app', () => {
	initLS('app')
	const versionUpdated = ref(ls('app.versionUpdated') ?? 0)
	initLS('app.versionUpdated', 0)

	const themeByClass = ()=> qs('html').classList.contains('md')?'md':'ios'
	const autoOs = ref(ls('app.autoOs') ?? themeByClass())
	initLS('app.autoOs', themeByClass())

	let messAva = initField('app.messAva', '')


	const recentAvas = initField('app.recentAvas', [])

	return { versionUpdated, autoOs, messAva, recentAvas }
})