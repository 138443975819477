export let defOptions = {
	browserHistory: 1,
	transition: 'md-flow',
}

let d = [
	{
		path: '/',
		asyncComponent: () => import('./../../pages/groups/groups.vue'),
		options: {
			...defOptions,
			keepAlive: true,

		},
	},
]

export default d