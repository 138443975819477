export let defOptions = {
	browserHistory: 1,
	transition: 'md-flow',
}

let d = [
	{
		path: '/',
		asyncComponent: () => import('./../../pages/settings/settings.vue'),
		options: {
			...defOptions,
			keepAlive: true,

		},
	},
	{
		path: '/settingsApp/',
		asyncComponent: () => import('./../../pages/settings/settingsApp.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/settingsPush/',
		asyncComponent: () => import('./../../pages/settings/settingsPush.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/settingsAchive/',
		asyncComponent: () => import('./../../pages/settings/settingsAchive.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/avaPrompt/',
		popup: {
			asyncComponent: () => import('./../../components/settings/ava/avaPrompt.vue'),
			closeOnEscape: true,
		},
		options: {
			...defOptions,

		},
	},
	{
		path: '/settingsDev/',
		asyncComponent: () => import('./../../pages/settings/settingsDev.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/settingsAppSound/',
		asyncComponent: () => import('./../../pages/settings/settingsAppSound.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/settingsAppVibro/',
		asyncComponent: () => import('./../../pages/settings/settingsAppVibro.vue'),
		options: {
			...defOptions,

		},
	},
	{
		path: '/avaChange/',
		asyncComponent: () => import('./../../pages/settings/avaChange.vue'),
		options: {
			...defOptions,

		},
	},
]

export default d