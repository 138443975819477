<template>
	<div class="text-container" ref="containerRef"
		:style="`--blur:${blur}px; --duration:${duration}s; --way:${way ? 1 : -1}`">
		<transition name="fade-slide" @before-enter="beforeEnter">
			<span v-if="showingOld" :key="currentKey + '-old'" ref="textRef" class="text">{{ oldText }}</span>
			<span v-else :key="currentKey + '-new'" class="text" ref="textRef">{{ currentText }}</span>
		</transition>
	</div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue';

const props = defineProps({
	text: {
		type: String,
		required: true,
		default: '',
	},
	blur: {
		type: Number,
		required: false,
		default: 4,
	},
	duration: {
		type: Number,
		required: false,
		default: 0.3,
	},
	up: {
		type: Boolean,
		required: false,
		default: undefined,
	},
	autoWidth: {
		type: Boolean,
		required: false,
		default: true,
	},
});

const oldText = ref('');
const currentText = ref(props.text);
const showingOld = ref(false);
const currentKey = ref(0);
const textRef = ref(null);
const containerRef = ref(null);
const way = ref(props.up);

watch(() => props.text, (newText) => {
	if (typeof props.up !== 'undefined') {
		way.value = props.up;
	} else {
		const extractNumbers = (str) => (str.match(/\d+/g) || []).map(Number);
		const currentNumbers = extractNumbers(oldText.value);
		const newNumbers = extractNumbers(newText);
		for (let i = 0; i < Math.min(currentNumbers.length, newNumbers.length); i++) {
			if (newNumbers[i] > currentNumbers[i]) {
				way.value = true;
				break;
			} else if (newNumbers[i] < currentNumbers[i]) {
				way.value = false;
				break;
			}
		}
	}
	oldText.value = props.text;
	showingOld.value = !showingOld.value;
	currentKey.value++;
	currentText.value = newText;
});

onMounted(() => {
	if ('fonts' in document) {
		document.fonts.ready.then(setFontAndWidth);
	} else {
		nextTick(setFontAndWidth);
	}
});

const beforeEnter = async () => {
	await nextTick();
	if (!props.autoWidth) return;
	const oldWidth = containerRef.value.offsetWidth;
	const newWidth = textRef.value.offsetWidth;
	containerRef.value.style.width = `${oldWidth}px`;
	nextTick(() => {
		containerRef.value.style.width = `${newWidth}px`;
	});
};

const setFontAndWidth = () => {
	if (!props.autoWidth) return;
	const textWidth = textRef.value.scrollWidth;
	containerRef.value.style.width = `${textWidth}px`;
};
</script>


<style scoped>
.text-container {
	vertical-align: bottom;
	transition: width calc(var(--duration) * 1);
	overflow-x: hidden;
	overflow-y: hidden;
	border-radius: 0;
	max-width: -webkit-fill-available;
	display: inline-grid;
	box-sizing: border-box;
}

.text {
	grid-area: 1 / 1;
	white-space: pre-wrap;
	transition: opacity calc(var(--duration) * 1), transform calc(var(--duration) * 1), filter calc(var(--duration) * 1);
	will-change: opacity, transform;
	width: max-content;
	box-sizing: border-box;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0.3;
  transform: translateY(calc(-100% * var(--way))) scale(0.9, 2);
  filter: blur(var(--blur));
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1, 1);
  filter: blur(0px);
}

.fade-slide-leave-active {
  transform: translateY(calc(100% * var(--way)));
  filter: blur(var(--blur));
}
</style>
