<template>
  <div class="feed">
	<div v-if="posts.postsArr.length" v-for="post in posts.postsArr" :key="post.id" class="postC">
		<Post :id="post.id" :title="post.title" :post="post"/>
	</div>
	<div class="msg center" v-if="!posts.postsArr.length && emptySearch">
		<div>Ничего не найдено</div>
		<div>Попробуйте убрать фильтры или упросить поиск</div>
	</div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { usePostsStore } from '../../../js/stores/postsStore';
import Post from './post.vue';
import { f7 } from 'framework7-vue';
import localforage from 'localforage';

const posts = usePostsStore();
let emptySearch = ref(false)

onMounted(async () => {
	setTimeout(() => {
		reload()
	}, 10);

	f7.on('e-homeRefreshed', reload)
	f7.on('e-searchCompleted', clearPosts)
})

onUnmounted(() => {
	f7.off('e-homeRefreshed', reload)
	f7.off('e-searchCompleted', clearPosts)
})



async function reload() {
	if (!state.user.login || !state.user.group.id || !state.user.hash) {
		return 0
	}
	// return 0
	// debugger
	let reloadSt = +new Date();
		let queryParams = new URLSearchParams({
		login: state.user.login,
		gid: state.user.group.id
	}).toString();

	const headers = {
		Authorization: `Bearer ${state.user.hash}`
	};
	let req = await fetch(`/api/getTasks?${queryParams}`, {
		method: 'GET',
		headers: headers,
	});
	let data = await req.json()
	let pposts = clearPosts(data)
	localforage.setItem('posts', pposts)

	

let time = (+new Date() - reloadSt)<200?500:0
	setTimeout(() => {
		// debugger
		sdone()
	}, time);

	function sdone() {
		// if (done) {
			// setTimeout(() => {
				let homePtr = qs('.homePtr')
			// homePtr.classList.remove('ptr-pull-up')
			// homePtr.classList.remove('ptr-transitioning')
			// homePtr.classList.remove('ptr-closing')
			homePtr.classList.remove('ptr-refreshing')
			// }, 500)
		// }
	}
}

function clearPosts(data) {
	console.log(data.length)
	if (data.length == 0) {
		emptySearch.value=true
	}
	let pposts = {}
	data.forEach(el => {
		pposts[`${el.id}^^::${el.title}`] = {
			title: el.title,
			blocks: el.blocks.b,
			id: el.id,
			status: el.status,
			creator_id: el.creator_id,
			created_at: +new Date(el.created_at),
			isLimited: el.is_limited,
			user: el.user,
			reactions: el.reactions,
			comment_count: +el.comment_count,
		}
	})
	console.log(pposts)
	posts.d = pposts
	console.log(emptySearch.value)
	return pposts
}

// posts.pos
// debugger
</script>
<style lang="less" scoped>
	.msg.center{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40vh;
		padding: 0 1rem;
		translate: 0% -50%;

		div{
			font-size: var(--uk-font-size);
			text-align: center;
		}
		div:first-of-type{
			margin-bottom: 0.2em;
			font-size: var(--uk-font-size-h2);;
		}
	}
</style>
