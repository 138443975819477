<template>
	<!-- бляя.. .big нельзя ставить потому что какое-то багулетти с цветами даже без риппла -->
	<a @click="cl" class="link bell popover-open ttip ripple ripple-blur" ttipText="Уведомления"
	data-popover=".popover-menu">
		<Transition name="scale" :duration="50000">
			<span ref="bellEl" class="msr masking lowOpacity"> 
				{{ bellVariant }}
			 </span>
		</Transition>
	</a>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useNotificationsStore } from '../../../js/stores/notificationsStore';

const store = useNotificationsStore()
const bellEl = ref(0)
let bellStates = {
	simple: 'notifications',
	active: 'notifications_active'
}
// let testitems = computed(()=>store.items.length)
let currentBell = computed(() => {
	return bellStates[store.items.length ? 'active' : 'simple']
})

let bellVariant = ref(currentBell.value)


function cl() {
	// debugger
	// window.popupProps = {
	// 	type: state.cards.cards.items[2]
	// }
	// app.view.current.router.navigate('/achive/CardMember/')
}

onMounted(() => {
	watch(currentBell, () => {
		bellEl.value.style.scale=0.7
		bellEl.value.style.opacity = 0
		bellEl.value.style.fontSize = '1.500001em'
		setTimeout(() => {
			bellVariant.value = currentBell.value
			bellEl.value.style.scale=''
			bellEl.value.style.opacity = ''
			setTimeout(() => {
				bellEl.value.style.fontSize = ''
			}, 50);
		}, 200);
	})
})

</script>