<template>
	<div class="groupData">
		<img class="ava ripple ripple-blur" :src="avaSrc" @click="openAva"/>
		<f7-button tonal class="name" @click="gname"><span class="msr" v-if="canEdit">edit</span>
			<DText :text="pr.group.name" />
		</f7-button>
		<!-- <f7-list> -->
		<!-- <f7-block style="margin: 1rem 0px;"> -->
			<f7-accordion-item>
				<f7-accordion-toggle><f7-button class="expandMore" @click="() => accordionOpened = !accordionOpened"> – 
					<DText :text="accordionOpened ? 'Меньше' : 'Больше'" :up="accordionOpened" /> –
				</f7-button></f7-accordion-toggle>
				<f7-accordion-content>
					<f7-list>
						<f7-list-input outline="false" resizable type="textarea" v-model:value="pr.group.disc"
						placeholder="Описание вашей группы" label="Описание" floating-label validate maxlength="100"
						:onValidate="(v) => gdiscV = v" :tabindex="canEdit?'':'-1'" :style="canEdit ? '' : 'pointer-events: none;'"></f7-list-input>
					</f7-list>
				</f7-accordion-content>
			</f7-accordion-item>
		<!-- </f7-block> -->
		<!-- </f7-list> -->
	</div>
	<input type="file" accept="image/png, image/jpeg, image/gif, image/webp, image/heif, image/bmp, image/svg+xml, image/tiff" ref="fileInput"
	@change="handleFile" id="groupPhotoBrowserUploadInput" style="display: none;"/>
</template>

<script setup>
import { f7 } from 'framework7-vue';
import { handleImg, validateStr } from '../../js/modules';
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import DText from '../ui/dText.vue';
import { qs } from '../../js/globalF';
import { width } from 'dom7';

let pr = defineProps(['group', 'canEdit'])
let emit = defineEmits(['upd'])
let gdiscV = ref(true)
let accordionOpened = ref(false)

let gdiscTimeout;


watch(() => pr.group.disc, () => {
	if (gdiscTimeout) {
		clearTimeout(gdiscTimeout)
	}
	gdiscTimeout = setTimeout(async () => {
		let req = await fetch('/api/updateGroup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				login: state.user.login,
				hash: state.user.hash,
				name: pr.group.name,
				code: pr.group.code,
				key: 'disc',
				value: pr.group.disc
			})
		})
		let data = await req.json()
	}, 1000);
	
	
})

function gname() {
	if (pr.canEdit) {
		function propmptt(def) {
			f7.dialog.prompt('Введите новое название', pr.group.code, async (val) => {
				let valid = validateStr({
					str: val,
					minlen: 3,
					maxlen: 25,
					name: 'названии'
				})
				if (valid !== true) {
					app.emit('e-error')
					f7.dialog.alert(valid, 'Ошибка', () => {
						propmptt(val)
					})
				} else {
					
					await fetch('/api/updateGroup', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							login: state.user.login,
							hash: state.user.hash,
							name: pr.group.name,
							code: pr.group.code,
							key: 'name',
							value: val
						})
					})
					emit('upd')
				}
			}, () => { }, def)
		}

		propmptt(pr.group.name)
	} else {
		f7.dialog.alert('Только админ может изменить название группы. Попросите его TODO СДЕЛАТЬ ВОЗМОЖНОСТЬ ОБРАЩЕНИЯ', '☝️')
	}
}
let standalone
function openAva() {
	Promise.all([import('framework7/components/photo-browser'), import('framework7/components/swiper'), import('framework7/components/photo-browser/css'), import('framework7/components/swiper/css')]).then(async (av) => {
		await Promise.all([f7.loadModule(av[0].default), f7.loadModule(av[1].default)])
		standalone = f7.photoBrowser.create({
			photos: [avaSrc.value],
			theme: 'dark',
			toolbar: pr.canEdit,
			navbar: false,
			exposition:!pr.canEdit,
			routableModals: true,
			renderToolbar() {
				return `
				<div class="toolbar toolbar-bottom tabbar">
					<div class="toolbar-inner">
						<a class="link" id="groupPhotoBrowserUploadNew" onclick="window.htmlFuncs.groupPhotoBrowserUploadNew()" role="button"><span class="msr nfill">cloud_upload</span>Загрузить новое фото</a>
					</div>
				</div>
				`
			}
		});
		standalone.open()
	})
	// import('framework7/components/photoBrowser')
	// .then(module => app.loadModule(module.default))
	// .then(() => {
	// 	// module loaded and we can use gauge api
	// 	import('framework7/components/photoBrowser/css')
	// 	popover = app.popover.create({
	// 		el: qs('.popoverGroupDos'),
			
	// 	})
	// })
}

window.htmlFuncs.groupPhotoBrowserUploadNew = function () {
	app.photoBrowser.get()?.close()
	setTimeout(() => {
		qs('#groupPhotoBrowserUploadInput').click()
	}, 100);
}

function handleFile(event) {
	
	let blob = handleImg(event, 'blob', 728, 0.7).then(async (res) => {
		console.log(res)
		const reader = new FileReader();
		reader.onloadend = async (base) => {
			let req = await fetch('/api/updateGroup', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					login: state.user.login,
					hash: state.user.hash,
					name: pr.group.name,
					code: pr.group.code,
					key: 'ava',
					value: base.target.result
				})
			})
			let data = await req.json()
			emit('upd')
		};
		reader.readAsDataURL(res);

		
	})
	
}
let avaEtag
async function loadAva() {
	if (!pr.group.name) {
		return 0
	}
	let queryParams = new URLSearchParams({
		login: state.user.login,
		name: pr.group.name,
		code: pr.group.code,
	}).toString();

	const headers = {
		Authorization: `Bearer ${state.user.hash}`
	};

	// Если ETag доступен, добавляем его в заголовки запроса
	if (avaEtag) {
		headers['If-None-Match'] = avaEtag;
	}

	let req = await fetch(`/api/getGroupAva?${queryParams}`, {
		method: 'GET',
		headers: headers,
	});

	// Если сервер возвращает 304 Not Modified, используем кешированные данные браузера
	if (req.status === 304) {
		console.log('Используем кешированные данные');
		// Здесь не нужно выполнять никаких действий, так как браузер автоматически использует кешированные данные
	} else if (req.ok) {
		let data = await req.json();
		pr.group.ava = data;
		avaSrc.value = 'data:image/webp;base64,' + data;
		// Сохраняем новый ETag для будущего использования
		let newEtag = req.headers.get('ETag');
		if (newEtag) {
			avaEtag = newEtag;
		}
	} else {
		// Обработка других HTTP статусов или ошибок
		console.error('Ошибка при загрузке аватара группы');
	}
}
watchEffect(() => {
	if (!pr.group.ava) {
		loadAva()
		return '/img/pix.png'
	}
	return 'data:image/webp;base64,' + pr.group.ava
})

let avaSrc = computed(() => {
	// return `https://s3.ichuk.ru/unic/${pr.group.ava}`
	return `https://storage.yandexcloud.net/unicimages/${pr.group.ava}`
})

onMounted(() => {
	watch(() => pr.group.name, (newVal, oldVal) => {
		if (newVal !== oldVal) {
			loadAva();
		}
	});
});
</script>

<style lang="less" scoped>
.groupData {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.ava {
	--size: 7rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	object-fit: cover;

	&[src="/img/pix.png"]{
		background: linear-gradient(90deg, #dcdcdc00, var(--f7-theme-color), #dcdcdc00, var(--f7-theme-color));
		background-size: 300% 100%;
		animation: wait 1s cubic-bezier(.59,-.03,.24,.99) forwards infinite;
	}

	@keyframes wait {
		0% {
    background-position: 0 0;
}
100% {
    background-position: 100% 0;
}
	}
}

.name {
	font-size: 14pt;
	border-radius: 20rem;
}
.accordion-item-toggle{
	width: fit-content;
	margin: 0 auto;
}
.accordion-item{
	width: 100%;
}
</style>