// Import Vue

import * as Sentry from "@sentry/browser";
import { createApp } from 'vue';

// Import Framework7
import Framework7 from './framework7-custom.js';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import '../css/framework7-custom.less';

// Import Icons and App Custom Styles

import '../css/app.less';

// Import App Component
import App from '../components/app.vue';
import { createPinia } from 'pinia';

// Сохраните оригинальную функцию fetch в другую переменную
const originalFetch = window.fetch;

// Переопределите функцию fetch
window.fetch = async (...args) => {
	if (args[0].startsWith('blob')) {
		const response = await originalFetch(...args);
		return response;
	} else {
		let cr = await encryptText(gf.getCookie('base'))
		if (args[1]?.body) {
			args[1].body = JSON.stringify(Object.assign(JSON.parse(args[1].body), { base: cr }))
		} else {
			if (args[0].indexOf('?') == -1) {
				args[0] += `?base=${cr}`
			} else {
				args[0] += `&base=${cr}`
			}
		}
		const response = await originalFetch(...args);

		window.gfbase = gf.getCookie('base')
		return response;
	}
	
};


import globalF from './globalF.js';
import { f7 } from 'framework7-vue';
import { $ } from 'dom7';



window.Sent = Sentry
window.htmlFuncs = {

}
// const replay = new Sentry.Replay();
// window.replay = replay


// Sentry.init({
// 	dsn: 'https://7cf50f4abe1f74b9cf188508e6575817@unic.ichuk.ru/sentry-proxy/4505964079546368',
// 	release: localStorage.version?.split('^')?.[0]??undefined,
//   // ... другие параметры конфигурации,
// 	integrations: [
// 		// new Sentry.Replay({
// 		// 	// Additional SDK configuration goes in here, for example:
// 		// 	maskAllText: true,
// 		// 	blockAllMedia: true,
// 		// }),
// 		new Sentry.BrowserTracing({
// 			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 			tracePropagationTargets: ["localhost", /^https:\/\/unic.ichuk.ru\./],
// 		}),
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// 	// Session Replay
// 	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
// Sentry.setExtra('store', localStorage.store);


// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

const pinia = createPinia()
window.state = pinia.state.value;

window.q=$




// Init App
const app = createApp(App);

// Register Framework7 Vue components
registerComponents(app);

app.use(pinia)

// Mount the app
app.mount('#app');
window.app = f7

if (localStorage.needToReload) {
	let time
	if (localStorage.needToReload == 'true') {
		time = 0
		delete localStorage.needToReload
		location.reload()
	} else if (+localStorage.needToReload) {
		time = +localStorage.needToReload
		gf.say(`Приложение перезапустится через ${(+localStorage.needToReload) / 1000} сек.`)
		setTimeout(() => {
			delete localStorage.needToReload
			location.reload()
		}, time);
	}
}

import { initModules } from './modules.js';
import { serverSync } from './modules/auth.js';
initModules()


globalF()

groupsInit()





import { s_check_version } from './s-check-version';import { groupsInit } from "./modules/groups.js";
 s_check_version(60_000)





window.pc = function perfCheck(iter, reverce, ...funcs) {
	iter ??= 1;
	reverce ??= false;
	let colors = '🔴 🟡 🟢 🔵 🟣 🟤'.split(' ');
	let functions = funcs.map((func, i) => [func.name + colors[i], func]);
	if (reverce) {
		functions = functions.reverse()
	}


	for (let func of functions) {
		console.time(func[0]);
		for (let j = 0; j < iter; j++) {
			func[1]();
		}
		console.timeEnd(func[0]);
	}

}

async function encryptText(text) {
	const encodedKey = new Uint8Array('af2aea95e5636c639e014050ae45051f'.match(/[\da-f]{2}/gi).map(function (h) {
		return parseInt(h, 16);
	}));;

	// Импортируем ключ
	const cryptoKey = await window.crypto.subtle.importKey(
		'raw',
		encodedKey,
		{ name: 'AES-CBC', length: 256 }, // Используем AES-CBC
		false,
		['encrypt']
	);

	// Шифруем данные
	const encodedText = new TextEncoder().encode(text);
	const iv = new Uint8Array('1dff95b2ef1262cc3743594845a0f455'.match(/[\da-f]{2}/gi).map(function (h) {
		return parseInt(h, 16);
	}));; // Инициализирующий вектор должен иметь длину 16 байт для режима AES-CBC
	const encryptedData = await window.crypto.subtle.encrypt(
		{ name: 'AES-CBC', iv: iv },
		cryptoKey,
		encodedText
	);

	// Возвращаем зашифрованные данные и инициализирующий вектор
	return Array.prototype.map.call(new Uint8Array(encryptedData), function (x) {
		return ('00' + x.toString(16)).slice(-2);
	}).join('');
}


async function sub() {
	const sw = await navigator.serviceWorker.register('sw.js', {
		type: 'module'
	});

	if (ls().user.login) {

		serverSync()

		navigator.serviceWorker.ready.then((registration) => {
			registration.active.postMessage(
				localStorage.store,
			);
			registration.pushManager.getSubscription().then(async (subscription) => {
				if (subscription) {
					console.log('Пользователь уже подписан:', subscription);
				} else {
					console.log('Пользователь не подписан');
					const push = await sw.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: 'BOWVd75uw-1kWxmIHGGH7V8F_B0RdqNTqS71ko0171L1qeS55zFBjXn5uLVGohi_JATLu45edO5Jdt4aiLuGNBY' // ваш публичный VAPID ключ
					});
					console.log(JSON.stringify(push))
					const subscription = JSON.stringify({ push, name: ls().user.login });
					fetch('/api/subscribe', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: subscription
					});
				}
			});
			
		});
	}

}
console.log(f7)
f7.on('e-removePushSub', () => {
	
	navigator.serviceWorker.ready.then((registration) => {
		console.log(1)
		registration.pushManager.getSubscription().then(async (subscription) => {
			console.log(JSON.stringify(subscription))
		});
	});
})

sub()