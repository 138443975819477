<template>
	<div :class="`wish ${0?'masking lowOpacity75':''}`">
		<span class="wish">
			<DText :text="currentWish"/> </span><span v-if="!short && name.length" class="name">, {{ name }}
		</span>
	</div>
</template>


<script setup>
import { useUserStore } from "../../../js/stores/userStore"
import {getRandomInt} from "../../../js/globalF"
import { computed, ref } from "vue";
import DText from "../../ui/dText.vue";

const store = useUserStore()
const props = defineProps(['short'])

// Пожелания
const schedule = {
	morning: {
		time:[6, 9],
		words: ['Доброе утро', 'Хорошего начала дня', 'Счастливого утра']
	},
	day: {
		time:[9, 19],
		words:["Продуктивного дня", "Отличного дня", "Хорошего дня", "Хорошего настроения"]
	},
	evening: {
		time:[19, 22],
		words:["Хорошего вечера", "Добрый вечер", "Удачи завтра"]
	},
	night: {
		time: [22, 6],
		words: ["Спокойной ночи", "Удачи завтра", "Хорошего отдыха",
			// 'Блабла бла', 'Биба боба', 'пукич'
		]
	}
}

// Определение времени
const hours = new Date().getHours()
// Определение текущего пожелания
const currentWishItem = Object.values(schedule).find(dayT => hours >= dayT.time[0] && hours < dayT.time[1])
	|| schedule.night 
const currentWish = computed(()=> window.wishGenerated || currentWishItem.words[getRandomInt(0, currentWishItem.words.length - 1, ch.value)])
window.wishGenerated = 0
let ch = ref(false)

//Имя
const name = computed(()=> store.name)

</script>