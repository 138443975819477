<template>
  <f7-button class="name ripple ripple-blur" @click="cl" tonal round>
	<span class="icon msr">edit</span>
	<span ref="bellEl"><DText :text='bellVariant.length ? bellVariant : "имя.."'/> </span>

  </f7-button>
</template>

<script setup>
import { f7 } from "framework7-vue";
import { onMounted, ref, unref, watch } from "vue";
import { useUserStore } from "../../js/stores/userStore";
import DText from "../ui/dText.vue";

const store = useUserStore()

function cl(ev, def) {
	if (def) openPrompt(ev, def);
	else
	setTimeout(() => {
		openPrompt(ev,def);
	}, def?0:100);
}

function openPrompt(ev, def) {
	f7.dialog.prompt(undefined, 'Сменить имя', (text) => {
		let nt = text.trim()
		//тут происходит ошибка, если введена пустая строка. но если исправить ошибку, окно успешно
		//закрывается при вводе пустоты. ошибка предотвращает закрытие
		nt = nt[0].toUpperCase() + nt.slice(1)

		if (nt.length < 3) error('Мало букв', text)
		else if (nt.length > 17) error('Много букв', text)
		else store.setName(text)
	}, () => { }, def || store.name)
}

const bellEl = ref(0)
let bellVariant = ref(store.name)
onMounted(() => {
	console.log('mounted')
	watch(()=>store.name, () => {
		console.log('change')
		bellVariant.value = unref(store.name)
		// bellEl.value.style.scale = 0.8
		// bellEl.value.style.opacity = 0
		setTimeout(() => {
				app.emit('e-succ', 1)
			}, 300);
	})
})

function error(text, lastText) {
	app.emit('e-error')
	f7.dialog.alert(text, 'Не-не-не', () => { cl(0,lastText) })
}
</script>