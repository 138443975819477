import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { initField, initLS, setLS, setLSc } from './storeUtils'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore('user', () => {
  const store = useUserStore()
  let th= this

  /** иички @type {number}*/
  let eggs = ref(0)
  initLS('user')
  
  let name = initField('user.name', '')
  let hash = initField('user.hash', '')
  let login = initField('user.login', '')

  let age = initField('user.age', -1)

  initLS('user.group')
  let group = ref({
    code: initField('user.group.code', ''),
    name: initField('user.group.name', ''),
    id: initField('user.group.id', ''),
    ava: initField('user.group.ava', ''),
  })

  let messenger = ref(ls()?.user?.messenger ?? '')
  initLS('user.messenger', '')
  let about = ref(ls()?.user?.about ?? '')
  initLS('user.about', '')
  
  const pro = ref(ls()?.user?.pro??false)
  initLS('user.pro',false)
  
  initLS('user.ava')
  const ava = ref({
    src: ls('user.ava.src')??'ava2.webp',
    local: ls('user.ava.local') ?? true
  })
  initLS('user.ava.src', ava.value.src)
  initLS('user.ava.local', true)

  const roles = ref([])

  let groupUsers = initField('user.groupUsers', [])

  // const getAva = computed(() => ava.value.local ? ('/img/avas/' + ava.value.src) : ava.value.src)
  const getAva = computed(() => (ava.value.src.split('?').length > 1) ? `https://storage.yandexcloud.net/unicimages/${ava.value.src}` : '/img/avas/' + ava.value.src+ '.webp')


  function save(check, callback) {
    console.log('save')
    let before = JSON.stringify(store.$state)
    if (callback) callback()
    setLS()
    let current = JSON.stringify(store.$state)
    if (before == current && !!check==false) return 0
    // console.log(current, th)
  }

  initLS('user.privacy')
  let privacy = ref({
    messenger: initField('user.privacy.messenger', false),
    age: initField('user.privacy.age', true),
    role: initField('user.privacy.role', true),
    about: initField('user.privacy.about', true),
  })

  watch(() => { return privacy }, function(){
    save(1)
  }, { deep: true })

  function setName(text) {
    let ntext = text.trim()
    if (name.value === ntext) return 0
    name.value = ntext
    setLSc('user.name', ntext)
  }

  

  return {
    eggs,
    name,
    hash,
    login,
    age,
    group,
    groupUsers,
    messenger,
    about,
    pro,
    ava,
    roles,
    privacy,
    getAva,
    setName,
    save

  }
})