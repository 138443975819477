<template>
  <Transition duration="500" name="opacity">
    <Loading v-if="!loaded"/>
  </Transition>
  <f7-app v-show="loaded || 1" v-bind="f7params">
    <f7-views tabs v-if="authed">
        <tabbar />
        <f7-view id="view-home" main tab tab-active>
          <Home />
        </f7-view>
        <f7-view id="view-catalog" tab>
          <Groups/>
        </f7-view>
        <f7-view id="view-settings" tab ref="settingsView">
          <Settings />
        </f7-view>
      </f7-views>
      <f7-view v-else id="view-auth" main>
          </f7-view>

  </f7-app>
</template>
<script>
import { ref, onMounted, computed, defineAsyncComponent } from 'vue';
import { f7, f7ready } from 'framework7-vue';

import { getDevice } from '../js/framework7-custom.js';
import capacitorApp from '../js/capacitor-app.js';
import routes from '../js/routes.js';
import Home from '../pages/home.vue';
import Tabbar from './tabbar/tabbar.vue';
import Settings from '../pages/settings/settings.vue';
import { useSettingsStore } from '../js/stores/settingsStore.js';
import { useUserStore } from '../js/stores/userStore.js';
import { useCardsStore } from '../js/stores/cardsStore.js';
import { $ } from 'dom7';
import Loading from './home/loading.vue';
import {eviromentDetect, waitUntil} from './../js/modules.js' 
import { useAppStore } from '../js/stores/appStore.js';

import settingsRoutes from './../js/routes/settings.js'
import groupsRoutes from './../js/routes/groups.js'
import homeRoutes from './../js/routes/home.js'
import Groups from '../pages/groups/groups.vue';


export default {
  setup() {
    const settingsView = ref(null)
    const store = useUserStore()
    const authed = computed(() => store.hash?.length)
    
    const authb = defineAsyncComponent(() => import('../pages/auth.vue'));

    
    const osTheme = ls()?.settings?.proSettings?.getOS ?? "auto"
    let shtorkaId = document.getElementById('shtorka')
    if (process.env.NODE_ENV === "production" && shtorkaId) {
      document.getElementById('shtorka').remove()
    }
    const device = getDevice();
    let firstColor = !ls()?.settings?.color?.current
    // Framework7 Parameters
    const f7params = {
      name: "unic",
      theme: osTheme,
      id: "io.chuk.unic",
      // App routes
      routes: routes,
      // Register service worker (only on production build)
      // serviceWorker: process.env.NODE_ENV === "production" ? {
      //   path: "/service-worker.js",
      // } : {
      //     path: "/service-worker.js",
      //     scope: {
      //     type: 'module',
      //    }
      // },
      // Input settings
      input: {
        scrollIntoViewOnFocus: device.capacitor,
        scrollIntoViewCentered: device.capacitor,
      },
      // Capacitor Statusbar settings
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
      },
      view: {
        iosDynamicNavbar: false,
        stackPages:true,
        animate: true,
        transition: 'f7-fade',
      },
      touch: {
        activeState: true,
        tapHoldDelay:550,
        tapHold: true,
        touchRippleElements: `.ripple, .ripple-dark-white, .fab>a, .button, .dialog-button, .link, a,
        .icon-checkbox, label:not(.toggle, .checkbox), .calendar-day-number, .actions-button`,
      },
      dialog: {
        closeByBackdropClick: true,
        buttonOk: "ОК",
        buttonCancel: 'Отмена'
      },
      
      view: {
        browserHistory: 1,
        browserHistorySeparator: "#",
        pushState: 1,
        allowDuplicateUrls: 0
      },
      colors: {
        // specify primary color theme
        primary: (ls()?.settings?.color?.current?.length?ls()?.settings?.color?.current:'#00ff00')
      }
    };
    onMounted(() => {

      f7.on('tabShow', (tabEl) => {
        localStorage.setItem('activeTab', tabEl.id);
      });

      const activeTabId = localStorage.getItem('activeTab');
      if (activeTabId) {
        f7.tab.show(`#${activeTabId}`);
      }
      // debugger
      f7ready(() => {
        $(document).on('click', () => {
          // gf.vibrate('fail')
        })

        

        

        window.timeInited = +new Date
        if (window.location.hash) {
          f7.views.forEach(view => {
            view.history = ['/']
            view.router.history = ['/']
            view.routes.length = 0
            console.log(view)
          })
          // app.router.routes.length = 0
          location.href = location.origin
          return 0
        }
        if (!authed.value) {
          f7.view.current.router.navigate('/auth/')
        }
        // Init capacitor APIs (see capacitor-app.js)
        if (device.capacitor) {
          capacitorApp.init(f7);
        }
        setTimeout(() => {
          const setingsStore = useSettingsStore()
          
          const cardsStore = useCardsStore()
          const appStore = useAppStore()
          
          if(firstColor) app.setColorTheme(setingsStore.color.current)
          if (qs('#view-settings')) {
            f7.views.create(qs('#view-settings'), {
              routesAdd: settingsRoutes,
            })
          }
          if (qs('#view-catalog')) {
            f7.views.create(qs('#view-catalog'), {
              routesAdd: groupsRoutes,
            })
          }
          if (qs('#view-home')) {
            f7.views.create(qs('#view-home'), {
              routesAdd: homeRoutes,
            })
          }
          
          
          
        }, 10);
        // Call F7 APIs here
      });
    });

    const loaded = ref(0);

    const testLoading = 0;
    
    if (!localStorage.store || localStorage.store=='{}' || testLoading) {
      waitUntil(()=> document.fonts.check("1em Material Symbols Rounded"), 10).then(function () {
        console.log('fonst')
        if(eviromentDetect()=='devi'){
          setTimeout(()=>loaded.value=1, 1500)
        }else
        loaded.value = 1 - testLoading
      });
    } else {
      if(eviromentDetect()=='devi'){
        setTimeout(()=>loaded.value=1, 1500)
      }else
      loaded.value = 1 - testLoading
    }

    return {
      f7params, loaded, authb, authed, testLoading
    };
  },
  components: { Home, Tabbar, Settings, Loading, Groups }
}
</script>