<template>
	<f7-page name="groups" :page-content="0">
		<f7-navbar large transparent :title="pageTitle" :large-title="pageTitle">
			<f7-nav-right>
				<a href="/group/" class="msr ripple-blur">more_vert</a>
			</f7-nav-right>
		</f7-navbar>
		<f7-page-content ptr ptr-distance="90" ref="pagec">
		<GroupData @upd="()=>reload()" v-if="ImInGroup && !noGroup" :group="group" :canEdit="canEdit"/>
		<JoinGroup v-if="noGroup"/>
		<UsersList v-if="loaded && !noGroup" :users="users">
			<template v-slot:before>
				<InviteToGroup v-if="ImInGroup" :group="group"/>
	  		</template>
		</UsersList>
		<f7-block v-if="!noGroup" style="margin: 0.7rem 0;">
			<f7-button @click="leaveGroup" color="red">Покинуть группу</f7-button>
		</f7-block>
		</f7-page-content>
	</f7-page>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import UserInList from '../../components/groups/userInList.vue';
import InviteToGroup from '../../components/groups/inviteToGroup.vue';
import JoinGroup from '../../components/groups/joinGroup.vue';
import GroupData from '../../components/groups/groupData.vue';
import UsersList from '../../components/groups/usersList.vue';
import { useUserStore } from '../../js/stores/userStore';
import strings from '../../js/modules/strings';
import { setLS } from '../../js/stores/storeUtils';

let ustore=useUserStore()
let loaded = ref(false)
let users = ref([])
let group = ref({
	name: '',
	code: '',
	disc:''
})
let pagec = ref(null)
let ImInGroup = computed(() => 1) //TODO норм условие
let noGroup = computed(() => !Array.isArray(users.value) || !users.value.length)
let pageTitle = computed(()=> group.value.code===''?'Загрузка':(noGroup.value ? 'Вы не состоите в группе' : `${group.value.code}`))
let popover;
let canEdit = computed(()=> !noGroup.value && users.value.find(el=>el.nick==state.user.login)?.role=='admin')
onMounted(async() => {
	setTimeout(async () => {
		await reload()
	}, 30);
	nextTick(() => {
		app.ptr.get(pagec.value.$el).on('refresh', async (el, done) => {
			await reload(el, done)
		})
		app.on('e-groupsRefresh', () => {
			reload()
		})
	})
})

async function reload(el, done) {
	if (!navigator.onLine) {
		gf.error({
			text: 'Нет интернета 😥.. не получается подгрузить одногруппников',
			time: 3000
		})
		sdone()
		return 0
	}
	let reloadSt = +new Date();
	let req = await fetch('/api/usersList', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			login: state.user.login,
			hash: state.user.hash
		})
	})
	let data = await req.json()
	group.value = data.group || data
	users.value = data.users || data
	ustore.roles = users.value.find(u => u.nick == ustore.login).role
	ustore.groupUsers = users.value
	console.log(group.value)
	ustore.group.id = group.value.id
	ustore.group.name = group.value.name
	ustore.group.code = group.value.code
	ustore.group.ava = group.value.ava
	loaded.value = true
	setLS()
	let time = (+new Date() - reloadSt)<200?500:0
	setTimeout(() => {
		// debugger
		sdone()
	}, time);

	function sdone() {
		if (done) {
			done()
			setTimeout(() => {
				el.classList.remove('ptr-pull-up')
				el.classList.remove('ptr-transitioning')
				el.classList.remove('ptr-closing')
			}, 500)
		}
	}
}


async function leaveGroup() {
	app.dialog.confirm(`Вы хотите покинуть группу «${group.value.code}»? ${strings.groups.leaveConfirm(1)}`, 'Подтвердите', async () => {
		let req = await fetch('/api/leaveGroup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				login: state.user.login,
				hash: state.user.hash,
				name: group.value.name,
				code: group.value.code,
			})
		})
		let data = await req.json()
		reload()
	})
}



// onMounted(() => {
//   let script = document.createElement('script');
//   script.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?22');
//   script.setAttribute('async', '');
//   script.setAttribute('data-telegram-login', 'unicAuthBot');
//   script.setAttribute('data-size', 'medium');
//   script.setAttribute('data-onauth', 'auth(user)');
//   script.setAttribute('data-request-access', 'write');

//   // Поместите скрипт в определенное место в документе
//   document.getElementById('telegramWidget').parentElement.appendChild(script);
// })

</script>

<style lang="less" scoped>
.ulist{
 --padding: 0.6rem 1rem;
--avaSize: 4rem;
}

</style>

<style lang="less">
.page[data-name="groups"]{
	.title-large-text{
	    white-space: pre-line;
}
}
</style>