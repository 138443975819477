import { defineStore } from 'pinia'
import { ref, watch, computed } from 'vue'
import { initField, initLS, setLS, setLSc } from './storeUtils'

export const useSettingsStore = defineStore('settings', () => {
	initLS('settings', {});
	const htmlEl = qs('html')
	
	
	
	//-----------------------------PRO--------------------------------------------
	const proSettings = ref({
		unlocked: ref(ls().settings?.proSettings?.unlocked ?? false),
		os: ref(ls().settings?.proSettings?.os ?? "Авто"),
		getOS: computed(function() {
			let v = {
				"Авто": "auto",
				"IOS": 'ios',
				"Android": 'md'
			}
			return v[proSettings.value.os]
		})
	})
	initLS('settings.proSettings');
	initLS('settings.proSettings.unlocked', false);
	initLS('settings.proSettings.os', "Авто");


//-----------------------------НАТИВНОСТЬ--------------------------------------------
	const native = ref(ls().settings.native ?? true)
	initLS('settings.native', true);
	initNative()
	watch(native, (v) => {
		initNative()
	})
	function initNative() {
		if (native.value) htmlEl.classList.add('native')
		else htmlEl.classList.remove('native')
	}
	//--------------------------------ВИБРАЦИЯ------------------------------------------
	const vibro = ref({
		status: ref(ls()?.settings?.vibro?.status ?? true),
		n: ref(ls()?.settings?.vibro?.n ?? 1),
		nn: ref(ls()?.settings?.vibro?.nn ?? 1),
		min:0.25,
		max:1.75,
	})
	initLS('settings.vibro');
	initLS('settings.vibro.status', true);
	initLS('settings.vibro.n', 1);
	initLS('settings.vibro.nn', 1);

	function setVibro(v) {
		if (typeof v == 'boolean') {
			vibro.value.status = v
		}
	}
	




	//----------------------------------ЦВЕТА-------------------------------------
	const color = ref({
		palette: [["#da8585", "#c285da"],
		["#8b85da", "#85dad5"],
		["#75c875", "#dad585"],
		["#daa085"]],
		current: ls()?.settings?.color?.current,
		sets: ref(ls()?.settings?.color?.sets ?? []),
		random: {
			status: ref(ls()?.settings?.color?.random?.status ?? false),
			timeSet: ls()?.settings?.color?.random?.timeSet ?? 0,
		}
	})
	initLS('settings.color', {});
	const randomColor = () => color.value.palette.flat()[gf.getRandomInRange(0, color.value.palette.flat().length - 1)]
	if (!color.value.current) color.value.current = randomColor()
	initLS('settings.color.current', color.value.current);
	initLS('settings.color.sets', []);
	initLS('settings.color.random', {});
	initLS('settings.color.random.status', false);
	initLS('settings.color.random.timeSet', 0);

	if (color.value.random.status) {
		if (new Date(color.value.random.timeSet).getDate() != new Date().getDate()) {
			let oldC = color.value.current
			while (oldC == color.value.current) {
				color.value.current = randomColor()
			}
			app.setColorTheme(color.value.current)
			color.value.random.timeSet = +new Date
			setLSc('settings.color.current', color.value.current)
			setLSc('settings.color.random.timeSet', color.value.random.timeSet)
		}
	}

	function setColor(hex) {
		if (hex == color.value.current) return 0
		app.setColorTheme(hex)
		color.value.current = hex
			if (color.value.sets.includes(hex)) {
				color.value.sets.splice(color.value.sets.indexOf(hex), 1)
				color.value.sets.push(hex)
			} else if (!(color.value.sets.length > 7)) {
				color.value.sets.push(hex)
			} else if (color.value.sets.length > 7) {
				color.value.sets.splice(0, 1)
				color.value.sets.push(hex)
				
			}
		setLS()
		
	}
	function setRandomColor() {
		color.value.random.status = true
		color.value.random.timeSet = +new Date
		setLS()
		setTimeout(() => {
			gf.say('Цвет изменится завтра')
		}, 700);
	}

	initLS('settings.lightTheme', "Системная");
	const lightTheme = ref(ls().settings.lightTheme ?? "Системная")


	//--------------------------------------ЗВУК----------------------------------------
	const sound = ref(ls()?.settings?.sound ?? true)
	initLS('settings.sound', true);
	const soundConfig = ref(ls()?.settings?.soundConfig ?? {
		taps: ref(ls()?.settings?.soundConfig?.taps ?? 75),
		notifications: ref(ls()?.settings?.soundConfig?.notifications ?? 75),
		blackout: ref(ls()?.settings?.soundConfig?.blackout ?? false),
	})
	initLS('settings.soundConfig', {});
	initLS('settings.soundConfig.taps', 75);
	initLS('settings.soundConfig.notifications', 75);
	initLS('settings.soundConfig.blackout', false);

	let theme = 'light';
	function setBlackout(v) {
		soundConfig._value.blackout = v
		if (theme == 'dark' && soundConfig._value.blackout && sound.value === true) {
			sound.value = false
			app.toast.create({ text: 'Город засыпает..   🌘', closeTimeout: 2000 }).open()
			setLS()
		}
		setLS()
	}
	function setSound(v) {
		if (theme == 'dark' && soundConfig._value.blackout && v) { 
			soundConfig._value.blackout = false
			setTimeout(() => {
				app.toast.create({ text: 'Город просыпается!   ☀', closeTimeout: 2000 }).open()
			}, 400);
		}
		sound.value = v
		setLS()
	}

	app.on('e-themeChanged', (themeP) => {
		if(themeP == theme) return 0
		theme=themeP
		if (themeP == 'dark' && soundConfig._value.blackout && sound.value === true) {
			sound.value = false
			setLS()
		} else if (themeP == 'light' && soundConfig._value.blackout) {
			sound.value = true
			setLS()
		}
	})

	//---------------------------------------УВЕДОМЛЕНИЯ--------------------------------------------
	initLS('settings.push')
	const push = ref({
		all: initField('settings.push.all', true),
		mention: initField('settings.push.mention', true),
		request: initField('settings.push.request', true),
		reply: initField('settings.push.reply', true),
	})
	// initLS('settings.push.all', push.value.all)
	// initLS('settings.push.mention', push.value.mention)


	return { proSettings, native, vibro, setVibro, sound, push, soundConfig, setBlackout, setSound, color, setColor, setRandomColor, lightTheme }
})