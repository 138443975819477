<template>
  <f7-page name="settings" :page-content="0">

  <f7-navbar large transparent title="Настройки" large-title="Настройки" />
  <f7-page-content>
    <Header />

    <f7-list dividers-ios strong-ios outline-ios>
        <f7-list-item class="ripple ripple-blur oh" footer="Данные, конфиденциальность" 
        title="Аккаунт" :link="`/settingsAccount/${store.login}/`">
          <template #media>
            <span class="msr">person</span>
          </template>
        </f7-list-item>
        <f7-list-item class="ripple ripple-blur oh" footer="Цвет из аватарки, бла, бла бла" 
        title="Премиум" link="/settingsApp/">
          <template #media>
            <span class="msr proIco">award_star</span>
          </template>
        </f7-list-item>
        <f7-list-item class="ripple ripple-blur oh" footer="Выбор типов уведомлений" 
        title="Уведомления" link="/settingsPush/">
          <template #media>
            <span class="msr">notifications</span>
          </template>
        </f7-list-item>
        <f7-list-item class="ripple ripple-blur oh" footer="Коллекционные карточки" 
        title="Достижения"  link="/settingsAchive/">
          <template #media>
            <span class="msr">social_leaderboard</span>
          </template>
        </f7-list-item>
        <f7-list-item class="ripple ripple-blur oh" footer="Цвета, нативность, вибрация, звуки, установка" 
        title="Приложение" link="/settingsApp/">
          <template #media>
            <span class="msr">tune</span>
          </template>
        </f7-list-item>
        

        

      </f7-list>

  </f7-page-content>
  </f7-page>

</template>

<script setup>
import './../../components/settings/settings.less'
import Header from '../../components/settings/header.vue';
import { useUserStore } from '../../js/stores/userStore';

const store = useUserStore()
window.ustore = store

</script>
