import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { initLS, setLS, setLSc } from './storeUtils'
import localforage from 'localforage'
/**
 * @typedef {import('./types/posts').Post} Post
 * @typedef {import('./types/posts').block} block
 * @typedef {import('./types/posts').User} User
 * @typedef {import('./types/posts').Comment} Comment
 */

export const usePostsStore = defineStore('posts', () => {

	
	/** post
	* @typedef rpost
	* @property {Post[]} value - Идентефикатор поста.
	*/

	/**  @type {rpost} */
	// debugger
	let d = ref({})
	window.localf = localforage
	getPostsLF()
	
	/** Объект поста по идентефикаторку
	 * @param {number} id идентефикатор
	 * @returns {Post}
	 */
	const postById = (id, title) => d.value[`${id}^^::${title}`]

	/** computed Массив постов
	 * @type {Post[]}
	 */
	const postsArr = computed(() => Object.values(d.value).toSorted((a, b) => b.created_at - a.created_at))
	let wrap = `
`
	
	/** @type {Object<number, Comment[]>}*/
	let comments = ref({})
	/** computed Массив постов
	 * @type {Post}
	 */
	let currentPost = ref({})
	let tags = {
		header: (block, format) => {
			if (format === 'md') {
				return `${'#'.repeat(block.data.level)} ${block.data.text}${wrap}`;
			} else {
				return `<h${block.data.level} style="${setAlign(block)}" id="${block.id}">${block.data.text}</h${block.data.level}>`;
			}
		},
		paragraph: (block, format) => {
			if (format === 'md') {
				return `${block.data.text.replace(/<a href="([^"]*)">([^<]*)<\/a>/g, '[$2]($1)') } ${wrap}`;
			} else {
				return `<p id="${block.id}" style="${setAlign(block)}">
                ${block.data.text.replaceAll('<a href=', '<a class="link external" target="_blank" href=')}
            </p>`;
			}
		},
		image: async (block, format, asBase) => {
			const url = `https://storage.yandexcloud.net/unicimages/${block.data.fileName}`;
			let href = asBase?await getImageBase64(url):url
			if (format === 'md') {
				return `![${block.data.caption}](${href})${wrap}`;
			} else {
				return `
            <figure class="${block.data.withBackground ? 'center' : ''}">
                <img id="${block.id}" src="${href}" alt="${block.data.caption}"/>
                <figcaption>${block.data.caption}</figcaption>
            </figure>`;
			}
		},
		list: (block, format) => {
			const ordered = block.data.style !== 'unordered';
			return createList(block.data.items, format, 0, ordered);
		},
		delimiter: (block, format) => {
			if (format === 'md') {
				return `---${wrap}`;
			} else {
				return `<hr id="${block.id}" />`;
			}
		},
		table: (block, format) => {
			if (format === 'md') {
				let header = '';
				let body = '';
				block.data.content.forEach((row, i) => {
					let separator = '| ' + row.map(() => '---').join(' | ') + ` |${wrap}`;
					let line = '| ' + row.join(' | ') + ` |${wrap}`;
					if (i === 0) {
						header = wrap+ line + separator;
					} else {
						body += line;
					}
				});
				return `${header}${body}${wrap}`;
			} else {
				return `<table>
                ${block.data.content.map((row, i) => {
					let ctag = (i == 0) ? 'th' : 'td';
					return `<tr>
                        ${row.map(cell => `
                            <${ctag}>${cell}</${ctag}>
                        `).join('')}
                    </tr>`;
				}).join('')}
            </table>`;
			}
		},
		code: (block, format) => {
			if (format === 'md') {
				return `\`\`\`${wrap}${block.data.code}${wrap}\`\`\`${wrap}`;
			} else {
				return `<code id="${block.id}">${block.data.code}</code>`;
			}
		}
	}

	async function content(post, format = 'html', asBlobs) {
		let blocks = post.blocks;
		let output = '';
		if (!blocks[0]) {
			return output;
		}
		const promises = blocks.map(async block => {
			let tagf = tags[block.type];
			if (tagf) {
				return await tagf(block, format, asBlobs);
			}
			return '';
		});

		const results = await Promise.all(promises);
		output = results.join('');
		return output;
	}

	function postDate(da) {
		let date = new Date(da)
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());

		let options = {
			hour: 'numeric',
			minute: 'numeric',
			hour12: false
		};

		if (dateWithoutTime.getTime() === today.getTime()) {
			// Сегодня
			return `сегодня в ${new Intl.DateTimeFormat('ru-RU', options).format(date)}`;
		} else if (dateWithoutTime.getTime() === yesterday.getTime()) {
			// Вчера
			return `вчера в ${new Intl.DateTimeFormat('ru-RU', options).format(date)}`;
		} else {
			// Другие дни
			options.day = 'numeric';
			options.month = 'long';

			// Добавляем год, если он отличается от текущего
			if (date.getFullYear() !== now.getFullYear()) {
				options.year = 'numeric';
			}

			return new Intl.DateTimeFormat('ru-RU', options).format(date);
		}
	}

	function setAlign(block) {
		let at = block?.tunes?.Align?.alignment ?? 'left'
		return `text-align:${at};`
	}

	async function getPostsLF() {
		setTimeout(async () => {
			console.time('getPostsLF')
			d.value = (await localf.getItem('posts')) ?? {}
			console.timeEnd('getPostsLF')
		}, 1);
	}

	function createList(items, format, depth = 0, ordered = false) {
		if (format === 'md') {
			const prefix = ordered ? `${'   '.repeat(depth)}1. ` : `${'   '.repeat(depth)}* `;
			return items.reduce((acc, item) => {
				let list = `${prefix}${item.content}${wrap}`;
				if (item.items && item.items.length > 0) {
					list += createList(item.items, format, depth + 1, ordered);
				}
				return acc + list;
			}, '');
		} else if (format === 'html') {
			const tag = ordered ? 'ol' : 'ul';
			return `<${tag}>${items.reduce((acc, item) => {
				let list = `<li>${item.content}`;
				if (item.items && item.items.length > 0) {
					list += createList(item.items, format, depth + 1, ordered);
				}
				return acc + list + '</li>';
			}, '')}</${tag}>`;
		}
		return '';
	}
	

	return {
		postById,
		postsArr,
		comments,
		currentPost,
		d,
		content,
		postDate,
		getPostsLF
	}
})

export const getImageBase64 = async (url) => {
	const response = await fetch(url);
	const blob = await response.blob();
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
};